/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * blabla
 * blabla
 * OpenAPI spec version: 1.0.0
 */
import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
	DefinedInitialDataOptions,
	DefinedUseInfiniteQueryResult,
	DefinedUseQueryResult,
	InfiniteData,
	MutationFunction,
	QueryFunction,
	QueryKey,
	UndefinedInitialDataOptions,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import type {
	ActiveEmployees,
	ActiveJobs,
	Address,
	AllowedAddressesType,
	AresResponse,
	BankAccount,
	BestAndWorstEmployees,
	BestAndWorstJobs,
	Comment,
	ConfirmQrCodeScanRequest,
	ContactPerson,
	Coordinate,
	CoordinateExtend,
	CreateAddress,
	CreateBankAccount,
	CreateContactPerson,
	CreateCoordinate,
	CreateCustomer,
	CreateEmail,
	CreateEmployee,
	CreateInvitation,
	CreateJob,
	CreateNotification,
	CreateOwner,
	CreateRequest,
	CreateTask,
	CreateTempNativeLog,
	CreateTime,
	CreateUser,
	CreateUserRole,
	CreateWorkSchedule,
	Customer,
	DailyEarningsWithTotalEarnings,
	DailyHoursWithTotalHours,
	Employee,
	EmployeeImportRequest,
	EmployeeImportResponse,
	EmployeeWithWorkSchedule,
	EmployeesCount,
	ErrorPayload,
	FieldArrayTimeParams,
	GetActiveEmployeesParams,
	GetActiveJobsParams,
	GetAddressesParams,
	GetAllowedAddressesTypesParams,
	GetBankAccountsParams,
	GetContactPersonsParams,
	GetCoordinatesParams,
	GetCustomersParams,
	GetDataForReviewTableParams,
	GetDayModalTimesParams,
	GetEmployeesParams,
	GetInvitationsParams,
	GetJobWithRelationsPaginatedResponse,
	GetJobsCostsParams,
	GetJobsParams,
	GetJobsProfitabilityPercentageParams,
	GetJobsWithTimesParams,
	GetMonthlyHoursOfAllEmployeesParams,
	GetMonthlySpendsParams,
	GetNotificationsParams,
	GetRequestsParams,
	GetSearchResultsParams,
	GetTaskDetails,
	GetTasksCostsStats,
	GetTasksParams,
	GetTasksStats,
	GetTasksTimeStats,
	GetTimeResponse,
	GetTimeTasksStats,
	GetTimesPaginatedResponse,
	GetTimesParams,
	GetUserRoleSettingsByOwnerIdParams,
	GetUserRolesByOwnerIdParams,
	GetUsersParams,
	GetWhisperAddressParams,
	GetWorkSchedulesParams,
	GroupedNotification,
	GroupedTimes,
	IcoRequest,
	Invitation,
	InviteEmployee,
	Job,
	JobWithRelations,
	JobsProfitabilityPercentage,
	JobsWithCosts,
	Notification,
	NotificationWithRelations,
	Owner,
	OwnerCardStats,
	OwnerWithRoles,
	Payload,
	QRCodeResponse,
	RegisterQrResponse,
	RegisterQrTransmitterRequest,
	Request,
	RequestWithRelations,
	ReviewTableResponse,
	Search,
	SendEmailResponse,
	StripeCheckoutRequest,
	StripeCheckoutResponse,
	StripePortalRequest,
	StripePortalResponse,
	Task,
	TaskWithRelations,
	Time,
	TimePage,
	UpdateAddress,
	UpdateBankAccount,
	UpdateComment,
	UpdateContactPerson,
	UpdateCustomer,
	UpdateEmployee,
	UpdateInvitation,
	UpdateJob,
	UpdateJobsBatch,
	UpdateNotification,
	UpdateOwner,
	UpdateRequest,
	UpdateTask,
	UpdateTasksBatch,
	UpdateTime,
	UpdateUser,
	UpdateUserRole,
	UpdateWorkSchedule,
	UpdateWorkSchedulesBatch,
	UploadAvatar,
	User,
	UserRole,
	UserRoleWithRelations,
	WhisperAddress,
	WorkSchedule,
} from '@shared/zod-schemas';
import { useCustomInstance } from '../use-fetch-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const useGetBestAndWorstJobsByProductivityHook = () => {
	const getBestAndWorstJobsByProductivity = useCustomInstance<BestAndWorstJobs>();

	return useCallback(
		(ownerId: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getBestAndWorstJobsByProductivity(
				{ url: `/api/${ownerId}/bestAndWorstJobs`, method: 'GET', signal },
				options,
			),
		[getBestAndWorstJobsByProductivity],
	);
};

export const getGetBestAndWorstJobsByProductivityQueryKey = (ownerId: string) =>
	[`/api/${ownerId}/bestAndWorstJobs`] as const;

export const useGetBestAndWorstJobsByProductivityQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
				TError,
				TData
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetBestAndWorstJobsByProductivityQueryKey(ownerId);

	const getBestAndWorstJobsByProductivity = useGetBestAndWorstJobsByProductivityHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>> = ({
		signal,
	}) => getBestAndWorstJobsByProductivity(ownerId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!ownerId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetBestAndWorstJobsByProductivityQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>
>;
export type GetBestAndWorstJobsByProductivityQueryError = ErrorPayload;

export function useGetBestAndWorstJobsByProductivity<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options: {
		query: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
				TError,
				TData
			>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBestAndWorstJobsByProductivity<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
				TError,
				TData
			>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBestAndWorstJobsByProductivity<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
				TError,
				TData
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetBestAndWorstJobsByProductivity<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstJobsByProductivityHook>>>,
				TError,
				TData
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetBestAndWorstJobsByProductivityQueryOptions(ownerId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetBestAndWorstEmployeesHook = () => {
	const getBestAndWorstEmployees = useCustomInstance<BestAndWorstEmployees>();

	return useCallback(
		(ownerId: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getBestAndWorstEmployees({ url: `/api/${ownerId}/bestAndWorst`, method: 'GET', signal }, options),
		[getBestAndWorstEmployees],
	);
};

export const getGetBestAndWorstEmployeesQueryKey = (ownerId: string) => [`/api/${ownerId}/bestAndWorst`] as const;

export const useGetBestAndWorstEmployeesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetBestAndWorstEmployeesQueryKey(ownerId);

	const getBestAndWorstEmployees = useGetBestAndWorstEmployeesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>> = ({
		signal,
	}) => getBestAndWorstEmployees(ownerId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!ownerId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetBestAndWorstEmployeesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>
>;
export type GetBestAndWorstEmployeesQueryError = ErrorPayload;

export function useGetBestAndWorstEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBestAndWorstEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBestAndWorstEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetBestAndWorstEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBestAndWorstEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetBestAndWorstEmployeesQueryOptions(ownerId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostCoordinateHook = () => {
	const postCoordinate = useCustomInstance<Coordinate>();

	return useCallback(
		(
			ownerId: string,
			createCoordinate: CreateCoordinate,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			postCoordinate(
				{
					url: `/api/${ownerId}/coordinates`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createCoordinate,
				},
				options,
			),
		[postCoordinate],
	);
};

export const usePostCoordinateMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCoordinateHook>>>,
		TError,
		{ ownerId: string; data: CreateCoordinate },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostCoordinateHook>>>,
	TError,
	{ ownerId: string; data: CreateCoordinate },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postCoordinate = usePostCoordinateHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostCoordinateHook>>>,
		{ ownerId: string; data: CreateCoordinate }
	> = (props) => {
		const { ownerId, data } = props ?? {};

		return postCoordinate(ownerId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostCoordinateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostCoordinateHook>>>>;
export type PostCoordinateMutationBody = CreateCoordinate;
export type PostCoordinateMutationError = ErrorPayload;

export const usePostCoordinate = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCoordinateHook>>>,
		TError,
		{ ownerId: string; data: CreateCoordinate },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostCoordinateHook>>>,
	TError,
	{ ownerId: string; data: CreateCoordinate },
	TContext
> => {
	const mutationOptions = usePostCoordinateMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetCoordinatesHook = () => {
	const getCoordinates = useCustomInstance<CoordinateExtend[]>();

	return useCallback(
		(
			ownerId: string,
			params: GetCoordinatesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getCoordinates({ url: `/api/${ownerId}/coordinates`, method: 'GET', params, signal }, options),
		[getCoordinates],
	);
};

export const getGetCoordinatesQueryKey = (ownerId: string, params: GetCoordinatesParams) =>
	[`/api/${ownerId}/coordinates`, ...(params ? [params] : [])] as const;

export const useGetCoordinatesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params: GetCoordinatesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCoordinatesQueryKey(ownerId, params);

	const getCoordinates = useGetCoordinatesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>> = ({ signal }) =>
		getCoordinates(ownerId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!ownerId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetCoordinatesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>>;
export type GetCoordinatesQueryError = ErrorPayload;

export function useGetCoordinates<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params: GetCoordinatesParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCoordinates<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params: GetCoordinatesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCoordinates<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params: GetCoordinatesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetCoordinates<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params: GetCoordinatesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoordinatesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetCoordinatesQueryOptions(ownerId, params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetEmployeesCountHook = () => {
	const getEmployeesCount = useCustomInstance<EmployeesCount>();

	return useCallback(
		(ownerId: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getEmployeesCount({ url: `/api/${ownerId}/employeeCount`, method: 'GET', signal }, options),
		[getEmployeesCount],
	);
};

export const getGetEmployeesCountQueryKey = (ownerId: string) => [`/api/${ownerId}/employeeCount`] as const;

export const useGetEmployeesCountQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetEmployeesCountQueryKey(ownerId);

	const getEmployeesCount = useGetEmployeesCountHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>> = ({ signal }) =>
		getEmployeesCount(ownerId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!ownerId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetEmployeesCountQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>
>;
export type GetEmployeesCountQueryError = ErrorPayload;

export function useGetEmployeesCount<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetEmployeesCount<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetEmployeesCount<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetEmployeesCount<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesCountHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetEmployeesCountQueryOptions(ownerId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetOwnerCardStatsHook = () => {
	const getOwnerCardStats = useCustomInstance<OwnerCardStats>();

	return useCallback(
		(ownerId: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getOwnerCardStats({ url: `/api/${ownerId}/stats`, method: 'GET', signal }, options),
		[getOwnerCardStats],
	);
};

export const getGetOwnerCardStatsQueryKey = (ownerId: string) => [`/api/${ownerId}/stats`] as const;

export const useGetOwnerCardStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOwnerCardStatsQueryKey(ownerId);

	const getOwnerCardStats = useGetOwnerCardStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>> = ({ signal }) =>
		getOwnerCardStats(ownerId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!ownerId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetOwnerCardStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>
>;
export type GetOwnerCardStatsQueryError = ErrorPayload;

export function useGetOwnerCardStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOwnerCardStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOwnerCardStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOwnerCardStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerCardStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetOwnerCardStatsQueryOptions(ownerId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetTaskDetailsHook = () => {
	const getTaskDetails = useCustomInstance<GetTaskDetails>();

	return useCallback(
		(
			ownerId: string,
			id: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getTaskDetails({ url: `/api/${ownerId}/tasks/${id}/detail`, method: 'GET', signal }, options),
		[getTaskDetails],
	);
};

export const getGetTaskDetailsQueryKey = (ownerId: string, id: string) =>
	[`/api/${ownerId}/tasks/${id}/detail`] as const;

export const useGetTaskDetailsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTaskDetailsQueryKey(ownerId, id);

	const getTaskDetails = useGetTaskDetailsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>> = ({ signal }) =>
		getTaskDetails(ownerId, id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && id), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTaskDetailsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>>;
export type GetTaskDetailsQueryError = ErrorPayload;

export function useGetTaskDetails<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetails<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetails<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTaskDetails<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTaskDetailsQueryOptions(ownerId, id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetTaskDetailTasksCostsStatsHook = () => {
	const getTaskDetailTasksCostsStats = useCustomInstance<GetTasksCostsStats>();

	return useCallback(
		(
			ownerId: string,
			id: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getTaskDetailTasksCostsStats(
				{ url: `/api/${ownerId}/tasks/${id}/stats/costs`, method: 'GET', signal },
				options,
			),
		[getTaskDetailTasksCostsStats],
	);
};

export const getGetTaskDetailTasksCostsStatsQueryKey = (ownerId: string, id: string) =>
	[`/api/${ownerId}/tasks/${id}/stats/costs`] as const;

export const useGetTaskDetailTasksCostsStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTaskDetailTasksCostsStatsQueryKey(ownerId, id);

	const getTaskDetailTasksCostsStats = useGetTaskDetailTasksCostsStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>> = ({
		signal,
	}) => getTaskDetailTasksCostsStats(ownerId, id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && id), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTaskDetailTasksCostsStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>
>;
export type GetTaskDetailTasksCostsStatsQueryError = ErrorPayload;

export function useGetTaskDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTaskDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksCostsStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTaskDetailTasksCostsStatsQueryOptions(ownerId, id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetTaskDetailTimeTasksStatsHook = () => {
	const getTaskDetailTimeTasksStats = useCustomInstance<GetTimeTasksStats>();

	return useCallback(
		(
			ownerId: string,
			id: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getTaskDetailTimeTasksStats(
				{ url: `/api/${ownerId}/tasks/${id}/stats/tasks`, method: 'GET', signal },
				options,
			),
		[getTaskDetailTimeTasksStats],
	);
};

export const getGetTaskDetailTimeTasksStatsQueryKey = (ownerId: string, id: string) =>
	[`/api/${ownerId}/tasks/${id}/stats/tasks`] as const;

export const useGetTaskDetailTimeTasksStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTaskDetailTimeTasksStatsQueryKey(ownerId, id);

	const getTaskDetailTimeTasksStats = useGetTaskDetailTimeTasksStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>> = ({
		signal,
	}) => getTaskDetailTimeTasksStats(ownerId, id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && id), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTaskDetailTimeTasksStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>
>;
export type GetTaskDetailTimeTasksStatsQueryError = ErrorPayload;

export function useGetTaskDetailTimeTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetailTimeTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetailTimeTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTaskDetailTimeTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTimeTasksStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTaskDetailTimeTasksStatsQueryOptions(ownerId, id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetTaskDetailTasksTimeStatsHook = () => {
	const getTaskDetailTasksTimeStats = useCustomInstance<GetTasksTimeStats>();

	return useCallback(
		(
			ownerId: string,
			id: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getTaskDetailTasksTimeStats(
				{ url: `/api/${ownerId}/tasks/${id}/stats/time`, method: 'GET', signal },
				options,
			),
		[getTaskDetailTasksTimeStats],
	);
};

export const getGetTaskDetailTasksTimeStatsQueryKey = (ownerId: string, id: string) =>
	[`/api/${ownerId}/tasks/${id}/stats/time`] as const;

export const useGetTaskDetailTasksTimeStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTaskDetailTasksTimeStatsQueryKey(ownerId, id);

	const getTaskDetailTasksTimeStats = useGetTaskDetailTasksTimeStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>> = ({
		signal,
	}) => getTaskDetailTasksTimeStats(ownerId, id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && id), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTaskDetailTasksTimeStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>
>;
export type GetTaskDetailTasksTimeStatsQueryError = ErrorPayload;

export function useGetTaskDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTaskDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskDetailTasksTimeStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTaskDetailTasksTimeStatsQueryOptions(ownerId, id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetJobDetailTasksCostsStatsHook = () => {
	const getJobDetailTasksCostsStats = useCustomInstance<GetTasksCostsStats>();

	return useCallback(
		(
			ownerId: string,
			jobId: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getJobDetailTasksCostsStats(
				{ url: `/api/${ownerId}/tasks/stats/${jobId}/costs`, method: 'GET', signal },
				options,
			),
		[getJobDetailTasksCostsStats],
	);
};

export const getGetJobDetailTasksCostsStatsQueryKey = (ownerId: string, jobId: string) =>
	[`/api/${ownerId}/tasks/stats/${jobId}/costs`] as const;

export const useGetJobDetailTasksCostsStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobDetailTasksCostsStatsQueryKey(ownerId, jobId);

	const getJobDetailTasksCostsStats = useGetJobDetailTasksCostsStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>> = ({
		signal,
	}) => getJobDetailTasksCostsStats(ownerId, jobId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && jobId), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobDetailTasksCostsStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>
>;
export type GetJobDetailTasksCostsStatsQueryError = ErrorPayload;

export function useGetJobDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobDetailTasksCostsStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksCostsStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobDetailTasksCostsStatsQueryOptions(ownerId, jobId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetJobDetailTasksStatsHook = () => {
	const getJobDetailTasksStats = useCustomInstance<GetTasksStats>();

	return useCallback(
		(
			ownerId: string,
			jobId: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getJobDetailTasksStats(
				{ url: `/api/${ownerId}/tasks/stats/${jobId}/tasks`, method: 'GET', signal },
				options,
			),
		[getJobDetailTasksStats],
	);
};

export const getGetJobDetailTasksStatsQueryKey = (ownerId: string, jobId: string) =>
	[`/api/${ownerId}/tasks/stats/${jobId}/tasks`] as const;

export const useGetJobDetailTasksStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobDetailTasksStatsQueryKey(ownerId, jobId);

	const getJobDetailTasksStats = useGetJobDetailTasksStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>> = ({
		signal,
	}) => getJobDetailTasksStats(ownerId, jobId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && jobId), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobDetailTasksStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>
>;
export type GetJobDetailTasksStatsQueryError = ErrorPayload;

export function useGetJobDetailTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobDetailTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobDetailTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobDetailTasksStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobDetailTasksStatsQueryOptions(ownerId, jobId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetJobDetailTasksTimeStatsHook = () => {
	const getJobDetailTasksTimeStats = useCustomInstance<GetTasksTimeStats>();

	return useCallback(
		(
			ownerId: string,
			jobId: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getJobDetailTasksTimeStats(
				{ url: `/api/${ownerId}/tasks/stats/${jobId}/time`, method: 'GET', signal },
				options,
			),
		[getJobDetailTasksTimeStats],
	);
};

export const getGetJobDetailTasksTimeStatsQueryKey = (ownerId: string, jobId: string) =>
	[`/api/${ownerId}/tasks/stats/${jobId}/time`] as const;

export const useGetJobDetailTasksTimeStatsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobDetailTasksTimeStatsQueryKey(ownerId, jobId);

	const getJobDetailTasksTimeStats = useGetJobDetailTasksTimeStatsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>> = ({
		signal,
	}) => getJobDetailTasksTimeStats(ownerId, jobId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && jobId), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobDetailTasksTimeStatsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>
>;
export type GetJobDetailTasksTimeStatsQueryError = ErrorPayload;

export function useGetJobDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobDetailTasksTimeStats<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	jobId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobDetailTasksTimeStatsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobDetailTasksTimeStatsQueryOptions(ownerId, jobId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateTimeByIdHook = () => {
	const updateTimeById = useCustomInstance<GetTimeResponse>();

	return useCallback(
		(
			ownerId: string,
			id: string,
			updateTime: UpdateTime,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			updateTimeById(
				{
					url: `/api/${ownerId}/times/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateTime,
				},
				options,
			),
		[updateTimeById],
	);
};

export const useUpdateTimeByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateTimeByIdHook>>>,
		TError,
		{ ownerId: string; id: string; data: UpdateTime },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateTimeByIdHook>>>,
	TError,
	{ ownerId: string; id: string; data: UpdateTime },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateTimeById = useUpdateTimeByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateTimeByIdHook>>>,
		{ ownerId: string; id: string; data: UpdateTime }
	> = (props) => {
		const { ownerId, id, data } = props ?? {};

		return updateTimeById(ownerId, id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateTimeByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateTimeByIdHook>>>>;
export type UpdateTimeByIdMutationBody = UpdateTime;
export type UpdateTimeByIdMutationError = ErrorPayload;

export const useUpdateTimeById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateTimeByIdHook>>>,
		TError,
		{ ownerId: string; id: string; data: UpdateTime },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateTimeByIdHook>>>,
	TError,
	{ ownerId: string; id: string; data: UpdateTime },
	TContext
> => {
	const mutationOptions = useUpdateTimeByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteTimeByIdHook = () => {
	const deleteTimeById = useCustomInstance<GetTimeResponse>();

	return useCallback(
		(ownerId: string, id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteTimeById({ url: `/api/${ownerId}/times/${id}`, method: 'DELETE' }, options),
		[deleteTimeById],
	);
};

export const useDeleteTimeByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteTimeByIdHook>>>,
		TError,
		{ ownerId: string; id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteTimeByIdHook>>>,
	TError,
	{ ownerId: string; id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteTimeById = useDeleteTimeByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteTimeByIdHook>>>,
		{ ownerId: string; id: string }
	> = (props) => {
		const { ownerId, id } = props ?? {};

		return deleteTimeById(ownerId, id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteTimeByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteTimeByIdHook>>>>;

export type DeleteTimeByIdMutationError = ErrorPayload;

export const useDeleteTimeById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteTimeByIdHook>>>,
		TError,
		{ ownerId: string; id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteTimeByIdHook>>>,
	TError,
	{ ownerId: string; id: string },
	TContext
> => {
	const mutationOptions = useDeleteTimeByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetTimeByIdHook = () => {
	const getTimeById = useCustomInstance<GetTimeResponse>();

	return useCallback(
		(
			ownerId: string,
			id: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getTimeById({ url: `/api/${ownerId}/times/${id}`, method: 'GET', signal }, options),
		[getTimeById],
	);
};

export const getGetTimeByIdQueryKey = (ownerId: string, id: string) => [`/api/${ownerId}/times/${id}`] as const;

export const useGetTimeByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTimeByIdQueryKey(ownerId, id);

	const getTimeById = useGetTimeByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>> = ({ signal }) =>
		getTimeById(ownerId, id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && id), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTimeByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>>;
export type GetTimeByIdQueryError = ErrorPayload;

export function useGetTimeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTimeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimeByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTimeByIdQueryOptions(ownerId, id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetDayModalTimesHook = () => {
	const getDayModalTimes = useCustomInstance<Time[]>();

	return useCallback(
		(
			ownerId: string,
			employeeId: string,
			taskId: string,
			params?: GetDayModalTimesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getDayModalTimes(
				{ url: `/api/${ownerId}/times/dayModal/${employeeId}/${taskId}`, method: 'GET', params, signal },
				options,
			),
		[getDayModalTimes],
	);
};

export const getGetDayModalTimesQueryKey = (
	ownerId: string,
	employeeId: string,
	taskId: string,
	params?: GetDayModalTimesParams,
) => [`/api/${ownerId}/times/dayModal/${employeeId}/${taskId}`, ...(params ? [params] : [])] as const;

export const useGetDayModalTimesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	employeeId: string,
	taskId: string,
	params?: GetDayModalTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetDayModalTimesQueryKey(ownerId, employeeId, taskId, params);

	const getDayModalTimes = useGetDayModalTimesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>> = ({ signal }) =>
		getDayModalTimes(ownerId, employeeId, taskId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && employeeId && taskId), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetDayModalTimesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>>;
export type GetDayModalTimesQueryError = ErrorPayload;

export function useGetDayModalTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	employeeId: string,
	taskId: string,
	params: undefined | GetDayModalTimesParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetDayModalTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	employeeId: string,
	taskId: string,
	params?: GetDayModalTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetDayModalTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	employeeId: string,
	taskId: string,
	params?: GetDayModalTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetDayModalTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	employeeId: string,
	taskId: string,
	params?: GetDayModalTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDayModalTimesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetDayModalTimesQueryOptions(ownerId, employeeId, taskId, params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostTimesArrayFieldHook = () => {
	const postTimesArrayField = useCustomInstance<Time[]>();

	return useCallback(
		(
			ownerId: string,
			fieldArrayTimeParams: FieldArrayTimeParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			postTimesArrayField(
				{
					url: `/api/${ownerId}/times/fieldArray`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: fieldArrayTimeParams,
				},
				options,
			),
		[postTimesArrayField],
	);
};

export const usePostTimesArrayFieldMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTimesArrayFieldHook>>>,
		TError,
		{ ownerId: string; data: FieldArrayTimeParams },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostTimesArrayFieldHook>>>,
	TError,
	{ ownerId: string; data: FieldArrayTimeParams },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postTimesArrayField = usePostTimesArrayFieldHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostTimesArrayFieldHook>>>,
		{ ownerId: string; data: FieldArrayTimeParams }
	> = (props) => {
		const { ownerId, data } = props ?? {};

		return postTimesArrayField(ownerId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostTimesArrayFieldMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostTimesArrayFieldHook>>>
>;
export type PostTimesArrayFieldMutationBody = FieldArrayTimeParams;
export type PostTimesArrayFieldMutationError = ErrorPayload;

export const usePostTimesArrayField = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTimesArrayFieldHook>>>,
		TError,
		{ ownerId: string; data: FieldArrayTimeParams },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostTimesArrayFieldHook>>>,
	TError,
	{ ownerId: string; data: FieldArrayTimeParams },
	TContext
> => {
	const mutationOptions = usePostTimesArrayFieldMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetGroupedTimesHook = () => {
	const getGroupedTimes = useCustomInstance<GroupedTimes[]>();

	return useCallback(
		(
			ownerId: string,
			taskId: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getGroupedTimes({ url: `/api/${ownerId}/times/groupedTimes/${taskId}`, method: 'GET', signal }, options),
		[getGroupedTimes],
	);
};

export const getGetGroupedTimesQueryKey = (ownerId: string, taskId: string) =>
	[`/api/${ownerId}/times/groupedTimes/${taskId}`] as const;

export const useGetGroupedTimesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	taskId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetGroupedTimesQueryKey(ownerId, taskId);

	const getGroupedTimes = useGetGroupedTimesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>> = ({ signal }) =>
		getGroupedTimes(ownerId, taskId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && taskId), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetGroupedTimesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>>;
export type GetGroupedTimesQueryError = ErrorPayload;

export function useGetGroupedTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	taskId: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGroupedTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	taskId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGroupedTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	taskId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetGroupedTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	taskId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedTimesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetGroupedTimesQueryOptions(ownerId, taskId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetTimesPagesHook = () => {
	const getTimesPages = useCustomInstance<TimePage>();

	return useCallback(
		(
			ownerId: string,
			cursor: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getTimesPages({ url: `/api/${ownerId}/times/pages/${cursor}`, method: 'GET', signal }, options),
		[getTimesPages],
	);
};

export const getGetTimesPagesQueryKey = (ownerId: string, cursor: string) =>
	[`/api/${ownerId}/times/pages/${cursor}`] as const;

export const useGetTimesPagesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	cursor: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTimesPagesQueryKey(ownerId, cursor);

	const getTimesPages = useGetTimesPagesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>> = ({ signal }) =>
		getTimesPages(ownerId, cursor, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(ownerId && cursor), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTimesPagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>>;
export type GetTimesPagesQueryError = ErrorPayload;

export function useGetTimesPages<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	cursor: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimesPages<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	cursor: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimesPages<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	cursor: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTimesPages<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	cursor: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesPagesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTimesPagesQueryOptions(ownerId, cursor, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostTimeHook = () => {
	const postTime = useCustomInstance<Time>();

	return useCallback(
		(ownerId: string, createTime: CreateTime, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postTime(
				{
					url: `/api/${ownerId}/times`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createTime,
				},
				options,
			),
		[postTime],
	);
};

export const usePostTimeMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTimeHook>>>,
		TError,
		{ ownerId: string; data: CreateTime },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostTimeHook>>>,
	TError,
	{ ownerId: string; data: CreateTime },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postTime = usePostTimeHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostTimeHook>>>,
		{ ownerId: string; data: CreateTime }
	> = (props) => {
		const { ownerId, data } = props ?? {};

		return postTime(ownerId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostTimeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostTimeHook>>>>;
export type PostTimeMutationBody = CreateTime;
export type PostTimeMutationError = ErrorPayload;

export const usePostTime = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTimeHook>>>,
		TError,
		{ ownerId: string; data: CreateTime },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostTimeHook>>>,
	TError,
	{ ownerId: string; data: CreateTime },
	TContext
> => {
	const mutationOptions = usePostTimeMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetTimesHook = () => {
	const getTimes = useCustomInstance<GetTimesPaginatedResponse>();

	return useCallback(
		(
			ownerId: string,
			params?: GetTimesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getTimes({ url: `/api/${ownerId}/times`, method: 'GET', params, signal }, options),
		[getTimes],
	);
};

export const getGetTimesQueryKey = (ownerId: string, params?: GetTimesParams) =>
	[`/api/${ownerId}/times`, ...(params ? [params] : [])] as const;

export const useGetTimesInfiniteQueryOptions = <
	TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, GetTimesParams['cursor']>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				QueryKey,
				GetTimesParams['cursor']
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTimesQueryKey(ownerId, params);

	const getTimes = useGetTimesHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
		QueryKey,
		GetTimesParams['cursor']
	> = ({ signal, pageParam }) =>
		getTimes(ownerId, { ...params, cursor: pageParam || params?.cursor }, requestOptions, signal);

	return {
		queryKey,
		queryFn,
		enabled: !!ownerId,
		keepPreviousData: true,
		...queryOptions,
	} as UseInfiniteQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
		TError,
		TData,
		Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
		QueryKey,
		GetTimesParams['cursor']
	> & { queryKey: QueryKey };
};

export type GetTimesInfiniteQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>>;
export type GetTimesInfiniteQueryError = ErrorPayload;

export function useGetTimesInfinite<
	TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, GetTimesParams['cursor']>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params: undefined | GetTimesParams,
	options: {
		query: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				QueryKey,
				GetTimesParams['cursor']
			>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
					TError,
					TData,
					QueryKey
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimesInfinite<
	TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, GetTimesParams['cursor']>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				QueryKey,
				GetTimesParams['cursor']
			>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
					TError,
					TData,
					QueryKey
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimesInfinite<
	TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, GetTimesParams['cursor']>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				QueryKey,
				GetTimesParams['cursor']
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTimesInfinite<
	TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, GetTimesParams['cursor']>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
				QueryKey,
				GetTimesParams['cursor']
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTimesInfiniteQueryOptions(ownerId, params, options);

	const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetTimesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
	TError = ErrorPayload,
>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTimesQueryKey(ownerId, params);

	const getTimes = useGetTimesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>> = ({ signal }) =>
		getTimes(ownerId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!ownerId, keepPreviousData: true, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTimesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>>;
export type GetTimesQueryError = ErrorPayload;

export function useGetTimes<TData = Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError = ErrorPayload>(
	ownerId: string,
	params: undefined | GetTimesParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimes<TData = Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError = ErrorPayload>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTimes<TData = Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError = ErrorPayload>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTimes<TData = Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError = ErrorPayload>(
	ownerId: string,
	params?: GetTimesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTimesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTimesQueryOptions(ownerId, params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateAddressByIdHook = () => {
	const updateAddressById = useCustomInstance<Address>();

	return useCallback(
		(id: string, updateAddress: UpdateAddress, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateAddressById(
				{
					url: `/api/addresses/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateAddress,
				},
				options,
			),
		[updateAddressById],
	);
};

export const useUpdateAddressByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateAddressByIdHook>>>,
		TError,
		{ id: string; data: UpdateAddress },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateAddressByIdHook>>>,
	TError,
	{ id: string; data: UpdateAddress },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateAddressById = useUpdateAddressByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateAddressByIdHook>>>,
		{ id: string; data: UpdateAddress }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateAddressById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateAddressByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateAddressByIdHook>>>
>;
export type UpdateAddressByIdMutationBody = UpdateAddress;
export type UpdateAddressByIdMutationError = ErrorPayload;

export const useUpdateAddressById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateAddressByIdHook>>>,
		TError,
		{ id: string; data: UpdateAddress },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateAddressByIdHook>>>,
	TError,
	{ id: string; data: UpdateAddress },
	TContext
> => {
	const mutationOptions = useUpdateAddressByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteAddressByIdHook = () => {
	const deleteAddressById = useCustomInstance<Address>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteAddressById({ url: `/api/addresses/${id}`, method: 'DELETE' }, options),
		[deleteAddressById],
	);
};

export const useDeleteAddressByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteAddressByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteAddressByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteAddressById = useDeleteAddressByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteAddressByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteAddressById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteAddressByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteAddressByIdHook>>>
>;

export type DeleteAddressByIdMutationError = ErrorPayload;

export const useDeleteAddressById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteAddressByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteAddressByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteAddressByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetAddressByIdHook = () => {
	const getAddressById = useCustomInstance<Address>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getAddressById({ url: `/api/addresses/${id}`, method: 'GET', signal }, options),
		[getAddressById],
	);
};

export const getGetAddressByIdQueryKey = (id: string) => [`/api/addresses/${id}`] as const;

export const useGetAddressByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAddressByIdQueryKey(id);

	const getAddressById = useGetAddressByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>> = ({ signal }) =>
		getAddressById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetAddressByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>>;
export type GetAddressByIdQueryError = ErrorPayload;

export function useGetAddressById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAddressById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAddressById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAddressById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetAddressByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetAllowedAddressesTypesHook = () => {
	const getAllowedAddressesTypes = useCustomInstance<AllowedAddressesType[]>();

	return useCallback(
		(
			params: GetAllowedAddressesTypesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getAllowedAddressesTypes(
				{ url: `/api/addresses/allowedAddressTypes`, method: 'GET', params, signal },
				options,
			),
		[getAllowedAddressesTypes],
	);
};

export const getGetAllowedAddressesTypesQueryKey = (params: GetAllowedAddressesTypesParams) =>
	[`/api/addresses/allowedAddressTypes`, ...(params ? [params] : [])] as const;

export const useGetAllowedAddressesTypesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAllowedAddressesTypesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllowedAddressesTypesQueryKey(params);

	const getAllowedAddressesTypes = useGetAllowedAddressesTypesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>> = ({
		signal,
	}) => getAllowedAddressesTypes(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetAllowedAddressesTypesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>
>;
export type GetAllowedAddressesTypesQueryError = ErrorPayload;

export function useGetAllowedAddressesTypes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAllowedAddressesTypesParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllowedAddressesTypes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAllowedAddressesTypesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllowedAddressesTypes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAllowedAddressesTypesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAllowedAddressesTypes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAllowedAddressesTypesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllowedAddressesTypesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetAllowedAddressesTypesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostAddressHook = () => {
	const postAddress = useCustomInstance<Address>();

	return useCallback(
		(createAddress: CreateAddress, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postAddress(
				{
					url: `/api/addresses`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createAddress,
				},
				options,
			),
		[postAddress],
	);
};

export const usePostAddressMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostAddressHook>>>,
		TError,
		{ data: CreateAddress },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostAddressHook>>>,
	TError,
	{ data: CreateAddress },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postAddress = usePostAddressHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostAddressHook>>>,
		{ data: CreateAddress }
	> = (props) => {
		const { data } = props ?? {};

		return postAddress(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostAddressMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostAddressHook>>>>;
export type PostAddressMutationBody = CreateAddress;
export type PostAddressMutationError = ErrorPayload;

export const usePostAddress = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostAddressHook>>>,
		TError,
		{ data: CreateAddress },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostAddressHook>>>,
	TError,
	{ data: CreateAddress },
	TContext
> => {
	const mutationOptions = usePostAddressMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetAddressesHook = () => {
	const getAddresses = useCustomInstance<Address[]>();

	return useCallback(
		(
			params: GetAddressesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getAddresses({ url: `/api/addresses`, method: 'GET', params, signal }, options),
		[getAddresses],
	);
};

export const getGetAddressesQueryKey = (params: GetAddressesParams) =>
	[`/api/addresses`, ...(params ? [params] : [])] as const;

export const useGetAddressesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAddressesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAddressesQueryKey(params);

	const getAddresses = useGetAddressesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>> = ({ signal }) =>
		getAddresses(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetAddressesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>>;
export type GetAddressesQueryError = ErrorPayload;

export function useGetAddresses<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAddressesParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAddresses<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAddressesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAddresses<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAddressesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetAddresses<
	TData = Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>,
	TError = ErrorPayload,
>(
	params: GetAddressesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddressesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetAddressesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostAresDataHook = () => {
	const postAresData = useCustomInstance<AresResponse>();

	return useCallback(
		(icoRequest: IcoRequest, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postAresData(
				{ url: `/api/ares`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: icoRequest },
				options,
			),
		[postAresData],
	);
};

export const usePostAresDataMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostAresDataHook>>>,
		TError,
		{ data: IcoRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostAresDataHook>>>,
	TError,
	{ data: IcoRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postAresData = usePostAresDataHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostAresDataHook>>>,
		{ data: IcoRequest }
	> = (props) => {
		const { data } = props ?? {};

		return postAresData(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostAresDataMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostAresDataHook>>>>;
export type PostAresDataMutationBody = IcoRequest;
export type PostAresDataMutationError = ErrorPayload;

export const usePostAresData = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostAresDataHook>>>,
		TError,
		{ data: IcoRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostAresDataHook>>>,
	TError,
	{ data: IcoRequest },
	TContext
> => {
	const mutationOptions = usePostAresDataMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostProfileAvatarHook = () => {
	const postProfileAvatar = useCustomInstance<Payload>();

	return useCallback(
		(uploadAvatar: UploadAvatar, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postProfileAvatar(
				{
					url: `/api/avatar/upload`,
					method: 'POST',
					headers: { 'Content-Type': 'multipart/form-data' },
					data: uploadAvatar,
				},
				options,
			),
		[postProfileAvatar],
	);
};

export const usePostProfileAvatarMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostProfileAvatarHook>>>,
		TError,
		{ data: UploadAvatar },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostProfileAvatarHook>>>,
	TError,
	{ data: UploadAvatar },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postProfileAvatar = usePostProfileAvatarHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostProfileAvatarHook>>>,
		{ data: UploadAvatar }
	> = (props) => {
		const { data } = props ?? {};

		return postProfileAvatar(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostProfileAvatarMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostProfileAvatarHook>>>
>;
export type PostProfileAvatarMutationBody = UploadAvatar;
export type PostProfileAvatarMutationError = ErrorPayload;

export const usePostProfileAvatar = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostProfileAvatarHook>>>,
		TError,
		{ data: UploadAvatar },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostProfileAvatarHook>>>,
	TError,
	{ data: UploadAvatar },
	TContext
> => {
	const mutationOptions = usePostProfileAvatarMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useUpdateBankAccountByIdHook = () => {
	const updateBankAccountById = useCustomInstance<BankAccount>();

	return useCallback(
		(
			id: string,
			updateBankAccount: UpdateBankAccount,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			updateBankAccountById(
				{
					url: `/api/bankAccount/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateBankAccount,
				},
				options,
			),
		[updateBankAccountById],
	);
};

export const useUpdateBankAccountByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateBankAccountByIdHook>>>,
		TError,
		{ id: string; data: UpdateBankAccount },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateBankAccountByIdHook>>>,
	TError,
	{ id: string; data: UpdateBankAccount },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateBankAccountById = useUpdateBankAccountByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateBankAccountByIdHook>>>,
		{ id: string; data: UpdateBankAccount }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateBankAccountById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateBankAccountByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateBankAccountByIdHook>>>
>;
export type UpdateBankAccountByIdMutationBody = UpdateBankAccount;
export type UpdateBankAccountByIdMutationError = ErrorPayload;

export const useUpdateBankAccountById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateBankAccountByIdHook>>>,
		TError,
		{ id: string; data: UpdateBankAccount },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateBankAccountByIdHook>>>,
	TError,
	{ id: string; data: UpdateBankAccount },
	TContext
> => {
	const mutationOptions = useUpdateBankAccountByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteBankAccountByIdHook = () => {
	const deleteBankAccountById = useCustomInstance<BankAccount>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteBankAccountById({ url: `/api/bankAccount/${id}`, method: 'DELETE' }, options),
		[deleteBankAccountById],
	);
};

export const useDeleteBankAccountByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteBankAccountByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteBankAccountByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteBankAccountById = useDeleteBankAccountByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteBankAccountByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteBankAccountById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteBankAccountByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteBankAccountByIdHook>>>
>;

export type DeleteBankAccountByIdMutationError = ErrorPayload;

export const useDeleteBankAccountById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteBankAccountByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteBankAccountByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteBankAccountByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetbankAccountByIdHook = () => {
	const getbankAccountById = useCustomInstance<BankAccount>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getbankAccountById({ url: `/api/bankAccount/${id}`, method: 'GET', signal }, options),
		[getbankAccountById],
	);
};

export const getGetbankAccountByIdQueryKey = (id: string) => [`/api/bankAccount/${id}`] as const;

export const useGetbankAccountByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetbankAccountByIdQueryKey(id);

	const getbankAccountById = useGetbankAccountByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>> = ({ signal }) =>
		getbankAccountById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetbankAccountByIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>
>;
export type GetbankAccountByIdQueryError = ErrorPayload;

export function useGetbankAccountById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetbankAccountById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetbankAccountById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetbankAccountById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetbankAccountByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetbankAccountByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostBankAccountHook = () => {
	const postBankAccount = useCustomInstance<BankAccount>();

	return useCallback(
		(createBankAccount: CreateBankAccount, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postBankAccount(
				{
					url: `/api/bankAccount`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createBankAccount,
				},
				options,
			),
		[postBankAccount],
	);
};

export const usePostBankAccountMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostBankAccountHook>>>,
		TError,
		{ data: CreateBankAccount },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostBankAccountHook>>>,
	TError,
	{ data: CreateBankAccount },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postBankAccount = usePostBankAccountHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostBankAccountHook>>>,
		{ data: CreateBankAccount }
	> = (props) => {
		const { data } = props ?? {};

		return postBankAccount(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostBankAccountMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostBankAccountHook>>>>;
export type PostBankAccountMutationBody = CreateBankAccount;
export type PostBankAccountMutationError = ErrorPayload;

export const usePostBankAccount = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostBankAccountHook>>>,
		TError,
		{ data: CreateBankAccount },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostBankAccountHook>>>,
	TError,
	{ data: CreateBankAccount },
	TContext
> => {
	const mutationOptions = usePostBankAccountMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetBankAccountsHook = () => {
	const getBankAccounts = useCustomInstance<BankAccount[]>();

	return useCallback(
		(
			params: GetBankAccountsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getBankAccounts({ url: `/api/bankAccount`, method: 'GET', params, signal }, options),
		[getBankAccounts],
	);
};

export const getGetBankAccountsQueryKey = (params: GetBankAccountsParams) =>
	[`/api/bankAccount`, ...(params ? [params] : [])] as const;

export const useGetBankAccountsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
	TError = ErrorPayload,
>(
	params: GetBankAccountsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetBankAccountsQueryKey(params);

	const getBankAccounts = useGetBankAccountsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>> = ({ signal }) =>
		getBankAccounts(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetBankAccountsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>>;
export type GetBankAccountsQueryError = ErrorPayload;

export function useGetBankAccounts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
	TError = ErrorPayload,
>(
	params: GetBankAccountsParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBankAccounts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
	TError = ErrorPayload,
>(
	params: GetBankAccountsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBankAccounts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
	TError = ErrorPayload,
>(
	params: GetBankAccountsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetBankAccounts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>,
	TError = ErrorPayload,
>(
	params: GetBankAccountsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBankAccountsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetBankAccountsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateCommentByIdHook = () => {
	const updateCommentById = useCustomInstance<Comment>();

	return useCallback(
		(id: string, updateComment: UpdateComment, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateCommentById(
				{
					url: `/api/comments/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateComment,
				},
				options,
			),
		[updateCommentById],
	);
};

export const useUpdateCommentByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateCommentByIdHook>>>,
		TError,
		{ id: string; data: UpdateComment },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateCommentByIdHook>>>,
	TError,
	{ id: string; data: UpdateComment },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateCommentById = useUpdateCommentByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateCommentByIdHook>>>,
		{ id: string; data: UpdateComment }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateCommentById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateCommentByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateCommentByIdHook>>>
>;
export type UpdateCommentByIdMutationBody = UpdateComment;
export type UpdateCommentByIdMutationError = ErrorPayload;

export const useUpdateCommentById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateCommentByIdHook>>>,
		TError,
		{ id: string; data: UpdateComment },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateCommentByIdHook>>>,
	TError,
	{ id: string; data: UpdateComment },
	TContext
> => {
	const mutationOptions = useUpdateCommentByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteCommentByIdHook = () => {
	const deleteCommentById = useCustomInstance<Comment>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteCommentById({ url: `/api/comments/${id}`, method: 'DELETE' }, options),
		[deleteCommentById],
	);
};

export const useDeleteCommentByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteCommentByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteCommentByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteCommentById = useDeleteCommentByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteCommentByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteCommentById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteCommentByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteCommentByIdHook>>>
>;

export type DeleteCommentByIdMutationError = ErrorPayload;

export const useDeleteCommentById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteCommentByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteCommentByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteCommentByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetCommentByIdHook = () => {
	const getCommentById = useCustomInstance<Comment>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getCommentById({ url: `/api/comments/${id}`, method: 'GET', signal }, options),
		[getCommentById],
	);
};

export const getGetCommentByIdQueryKey = (id: string) => [`/api/comments/${id}`] as const;

export const useGetCommentByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCommentByIdQueryKey(id);

	const getCommentById = useGetCommentByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>> = ({ signal }) =>
		getCommentById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetCommentByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>>;
export type GetCommentByIdQueryError = ErrorPayload;

export function useGetCommentById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCommentById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCommentById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetCommentById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCommentByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetCommentByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateContactPersonByIdHook = () => {
	const updateContactPersonById = useCustomInstance<ContactPerson>();

	return useCallback(
		(
			id: string,
			updateContactPerson: UpdateContactPerson,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			updateContactPersonById(
				{
					url: `/api/contactPerson/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateContactPerson,
				},
				options,
			),
		[updateContactPersonById],
	);
};

export const useUpdateContactPersonByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateContactPersonByIdHook>>>,
		TError,
		{ id: string; data: UpdateContactPerson },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateContactPersonByIdHook>>>,
	TError,
	{ id: string; data: UpdateContactPerson },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateContactPersonById = useUpdateContactPersonByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateContactPersonByIdHook>>>,
		{ id: string; data: UpdateContactPerson }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateContactPersonById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateContactPersonByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateContactPersonByIdHook>>>
>;
export type UpdateContactPersonByIdMutationBody = UpdateContactPerson;
export type UpdateContactPersonByIdMutationError = ErrorPayload;

export const useUpdateContactPersonById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateContactPersonByIdHook>>>,
		TError,
		{ id: string; data: UpdateContactPerson },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateContactPersonByIdHook>>>,
	TError,
	{ id: string; data: UpdateContactPerson },
	TContext
> => {
	const mutationOptions = useUpdateContactPersonByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteContactPersonByIdHook = () => {
	const deleteContactPersonById = useCustomInstance<ContactPerson>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteContactPersonById({ url: `/api/contactPerson/${id}`, method: 'DELETE' }, options),
		[deleteContactPersonById],
	);
};

export const useDeleteContactPersonByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteContactPersonByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteContactPersonByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteContactPersonById = useDeleteContactPersonByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteContactPersonByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteContactPersonById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteContactPersonByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteContactPersonByIdHook>>>
>;

export type DeleteContactPersonByIdMutationError = ErrorPayload;

export const useDeleteContactPersonById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteContactPersonByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteContactPersonByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteContactPersonByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetContactPersonByIdHook = () => {
	const getContactPersonById = useCustomInstance<ContactPerson>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getContactPersonById({ url: `/api/contactPerson/${id}`, method: 'GET', signal }, options),
		[getContactPersonById],
	);
};

export const getGetContactPersonByIdQueryKey = (id: string) => [`/api/contactPerson/${id}`] as const;

export const useGetContactPersonByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetContactPersonByIdQueryKey(id);

	const getContactPersonById = useGetContactPersonByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>> = ({ signal }) =>
		getContactPersonById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetContactPersonByIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>
>;
export type GetContactPersonByIdQueryError = ErrorPayload;

export function useGetContactPersonById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetContactPersonById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetContactPersonById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetContactPersonById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetContactPersonByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostContactPersonHook = () => {
	const postContactPerson = useCustomInstance<ContactPerson>();

	return useCallback(
		(createContactPerson: CreateContactPerson, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postContactPerson(
				{
					url: `/api/contactPerson`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createContactPerson,
				},
				options,
			),
		[postContactPerson],
	);
};

export const usePostContactPersonMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostContactPersonHook>>>,
		TError,
		{ data: CreateContactPerson },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostContactPersonHook>>>,
	TError,
	{ data: CreateContactPerson },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postContactPerson = usePostContactPersonHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostContactPersonHook>>>,
		{ data: CreateContactPerson }
	> = (props) => {
		const { data } = props ?? {};

		return postContactPerson(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostContactPersonMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostContactPersonHook>>>
>;
export type PostContactPersonMutationBody = CreateContactPerson;
export type PostContactPersonMutationError = ErrorPayload;

export const usePostContactPerson = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostContactPersonHook>>>,
		TError,
		{ data: CreateContactPerson },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostContactPersonHook>>>,
	TError,
	{ data: CreateContactPerson },
	TContext
> => {
	const mutationOptions = usePostContactPersonMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetContactPersonsHook = () => {
	const getContactPersons = useCustomInstance<ContactPerson[]>();

	return useCallback(
		(
			params: GetContactPersonsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getContactPersons({ url: `/api/contactPerson`, method: 'GET', params, signal }, options),
		[getContactPersons],
	);
};

export const getGetContactPersonsQueryKey = (params: GetContactPersonsParams) =>
	[`/api/contactPerson`, ...(params ? [params] : [])] as const;

export const useGetContactPersonsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
	TError = ErrorPayload,
>(
	params: GetContactPersonsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetContactPersonsQueryKey(params);

	const getContactPersons = useGetContactPersonsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>> = ({ signal }) =>
		getContactPersons(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetContactPersonsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>
>;
export type GetContactPersonsQueryError = ErrorPayload;

export function useGetContactPersons<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
	TError = ErrorPayload,
>(
	params: GetContactPersonsParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetContactPersons<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
	TError = ErrorPayload,
>(
	params: GetContactPersonsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetContactPersons<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
	TError = ErrorPayload,
>(
	params: GetContactPersonsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetContactPersons<
	TData = Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>,
	TError = ErrorPayload,
>(
	params: GetContactPersonsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContactPersonsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetContactPersonsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateCustomerByIdHook = () => {
	const updateCustomerById = useCustomInstance<Customer>();

	return useCallback(
		(id: string, updateCustomer: UpdateCustomer, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateCustomerById(
				{
					url: `/api/customers/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateCustomer,
				},
				options,
			),
		[updateCustomerById],
	);
};

export const useUpdateCustomerByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateCustomerByIdHook>>>,
		TError,
		{ id: string; data: UpdateCustomer },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateCustomerByIdHook>>>,
	TError,
	{ id: string; data: UpdateCustomer },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateCustomerById = useUpdateCustomerByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateCustomerByIdHook>>>,
		{ id: string; data: UpdateCustomer }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateCustomerById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateCustomerByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateCustomerByIdHook>>>
>;
export type UpdateCustomerByIdMutationBody = UpdateCustomer;
export type UpdateCustomerByIdMutationError = ErrorPayload;

export const useUpdateCustomerById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateCustomerByIdHook>>>,
		TError,
		{ id: string; data: UpdateCustomer },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateCustomerByIdHook>>>,
	TError,
	{ id: string; data: UpdateCustomer },
	TContext
> => {
	const mutationOptions = useUpdateCustomerByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteCustomerByIdHook = () => {
	const deleteCustomerById = useCustomInstance<Customer>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteCustomerById({ url: `/api/customers/${id}`, method: 'DELETE' }, options),
		[deleteCustomerById],
	);
};

export const useDeleteCustomerByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteCustomerByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteCustomerByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteCustomerById = useDeleteCustomerByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteCustomerByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteCustomerById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteCustomerByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteCustomerByIdHook>>>
>;

export type DeleteCustomerByIdMutationError = ErrorPayload;

export const useDeleteCustomerById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteCustomerByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteCustomerByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteCustomerByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetCustomerByIdHook = () => {
	const getCustomerById = useCustomInstance<Customer>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getCustomerById({ url: `/api/customers/${id}`, method: 'GET', signal }, options),
		[getCustomerById],
	);
};

export const getGetCustomerByIdQueryKey = (id: string) => [`/api/customers/${id}`] as const;

export const useGetCustomerByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCustomerByIdQueryKey(id);

	const getCustomerById = useGetCustomerByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>> = ({ signal }) =>
		getCustomerById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetCustomerByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>>;
export type GetCustomerByIdQueryError = ErrorPayload;

export function useGetCustomerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCustomerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCustomerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetCustomerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomerByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetCustomerByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostCustomerHook = () => {
	const postCustomer = useCustomInstance<Customer>();

	return useCallback(
		(createCustomer: CreateCustomer, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postCustomer(
				{
					url: `/api/customers`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createCustomer,
				},
				options,
			),
		[postCustomer],
	);
};

export const usePostCustomerMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCustomerHook>>>,
		TError,
		{ data: CreateCustomer },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostCustomerHook>>>,
	TError,
	{ data: CreateCustomer },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postCustomer = usePostCustomerHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostCustomerHook>>>,
		{ data: CreateCustomer }
	> = (props) => {
		const { data } = props ?? {};

		return postCustomer(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostCustomerMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostCustomerHook>>>>;
export type PostCustomerMutationBody = CreateCustomer;
export type PostCustomerMutationError = ErrorPayload;

export const usePostCustomer = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCustomerHook>>>,
		TError,
		{ data: CreateCustomer },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostCustomerHook>>>,
	TError,
	{ data: CreateCustomer },
	TContext
> => {
	const mutationOptions = usePostCustomerMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetCustomersHook = () => {
	const getCustomers = useCustomInstance<Customer[]>();

	return useCallback(
		(
			params?: GetCustomersParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getCustomers({ url: `/api/customers`, method: 'GET', params, signal }, options),
		[getCustomers],
	);
};

export const getGetCustomersQueryKey = (params?: GetCustomersParams) =>
	[`/api/customers`, ...(params ? [params] : [])] as const;

export const useGetCustomersQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
	TError = ErrorPayload,
>(
	params?: GetCustomersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCustomersQueryKey(params);

	const getCustomers = useGetCustomersHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>> = ({ signal }) =>
		getCustomers(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetCustomersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>>;
export type GetCustomersQueryError = ErrorPayload;

export function useGetCustomers<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetCustomersParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCustomers<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
	TError = ErrorPayload,
>(
	params?: GetCustomersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCustomers<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
	TError = ErrorPayload,
>(
	params?: GetCustomersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetCustomers<
	TData = Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>,
	TError = ErrorPayload,
>(
	params?: GetCustomersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCustomersHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetCustomersQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateEmployeeByIdHook = () => {
	const updateEmployeeById = useCustomInstance<Employee>();

	return useCallback(
		(id: string, updateEmployee: UpdateEmployee, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateEmployeeById(
				{
					url: `/api/employees/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateEmployee,
				},
				options,
			),
		[updateEmployeeById],
	);
};

export const useUpdateEmployeeByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeByIdHook>>>,
		TError,
		{ id: string; data: UpdateEmployee },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeByIdHook>>>,
	TError,
	{ id: string; data: UpdateEmployee },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateEmployeeById = useUpdateEmployeeByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeByIdHook>>>,
		{ id: string; data: UpdateEmployee }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateEmployeeById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateEmployeeByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeByIdHook>>>
>;
export type UpdateEmployeeByIdMutationBody = UpdateEmployee;
export type UpdateEmployeeByIdMutationError = ErrorPayload;

export const useUpdateEmployeeById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeByIdHook>>>,
		TError,
		{ id: string; data: UpdateEmployee },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateEmployeeByIdHook>>>,
	TError,
	{ id: string; data: UpdateEmployee },
	TContext
> => {
	const mutationOptions = useUpdateEmployeeByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteEmployeeByIdHook = () => {
	const deleteEmployeeById = useCustomInstance<Employee>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteEmployeeById({ url: `/api/employees/${id}`, method: 'DELETE' }, options),
		[deleteEmployeeById],
	);
};

export const useDeleteEmployeeByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteEmployeeById = useDeleteEmployeeByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteEmployeeById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteEmployeeByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeByIdHook>>>
>;

export type DeleteEmployeeByIdMutationError = ErrorPayload;

export const useDeleteEmployeeById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteEmployeeByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteEmployeeByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetEmployeeByIdHook = () => {
	const getEmployeeById = useCustomInstance<Employee>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getEmployeeById({ url: `/api/employees/${id}`, method: 'GET', signal }, options),
		[getEmployeeById],
	);
};

export const getGetEmployeeByIdQueryKey = (id: string) => [`/api/employees/${id}`] as const;

export const useGetEmployeeByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetEmployeeByIdQueryKey(id);

	const getEmployeeById = useGetEmployeeByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>> = ({ signal }) =>
		getEmployeeById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetEmployeeByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>>;
export type GetEmployeeByIdQueryError = ErrorPayload;

export function useGetEmployeeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetEmployeeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetEmployeeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetEmployeeById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeeByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetEmployeeByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetActiveEmployeesHook = () => {
	const getActiveEmployees = useCustomInstance<ActiveEmployees>();

	return useCallback(
		(
			params: GetActiveEmployeesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getActiveEmployees({ url: `/api/employees/activeEmployees`, method: 'GET', params, signal }, options),
		[getActiveEmployees],
	);
};

export const getGetActiveEmployeesQueryKey = (params: GetActiveEmployeesParams) =>
	[`/api/employees/activeEmployees`, ...(params ? [params] : [])] as const;

export const useGetActiveEmployeesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetActiveEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetActiveEmployeesQueryKey(params);

	const getActiveEmployees = useGetActiveEmployeesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>> = ({ signal }) =>
		getActiveEmployees(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetActiveEmployeesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>
>;
export type GetActiveEmployeesQueryError = ErrorPayload;

export function useGetActiveEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetActiveEmployeesParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetActiveEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetActiveEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetActiveEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetActiveEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetActiveEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetActiveEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetActiveEmployeesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostWorkScheduleBatchHook = () => {
	const postWorkScheduleBatch = useCustomInstance<Payload>();

	return useCallback(
		(
			updateWorkSchedulesBatch: UpdateWorkSchedulesBatch,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			postWorkScheduleBatch(
				{
					url: `/api/employees/batch`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: updateWorkSchedulesBatch,
				},
				options,
			),
		[postWorkScheduleBatch],
	);
};

export const usePostWorkScheduleBatchMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleBatchHook>>>,
		TError,
		{ data: UpdateWorkSchedulesBatch },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleBatchHook>>>,
	TError,
	{ data: UpdateWorkSchedulesBatch },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postWorkScheduleBatch = usePostWorkScheduleBatchHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleBatchHook>>>,
		{ data: UpdateWorkSchedulesBatch }
	> = (props) => {
		const { data } = props ?? {};

		return postWorkScheduleBatch(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostWorkScheduleBatchMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleBatchHook>>>
>;
export type PostWorkScheduleBatchMutationBody = UpdateWorkSchedulesBatch;
export type PostWorkScheduleBatchMutationError = ErrorPayload;

export const usePostWorkScheduleBatch = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleBatchHook>>>,
		TError,
		{ data: UpdateWorkSchedulesBatch },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleBatchHook>>>,
	TError,
	{ data: UpdateWorkSchedulesBatch },
	TContext
> => {
	const mutationOptions = usePostWorkScheduleBatchMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostEmployeeImportHook = () => {
	const postEmployeeImport = useCustomInstance<EmployeeImportResponse[]>();

	return useCallback(
		(
			employeeImportRequest: EmployeeImportRequest,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			postEmployeeImport(
				{
					url: `/api/employees/import`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: employeeImportRequest,
				},
				options,
			),
		[postEmployeeImport],
	);
};

export const usePostEmployeeImportMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostEmployeeImportHook>>>,
		TError,
		{ data: EmployeeImportRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostEmployeeImportHook>>>,
	TError,
	{ data: EmployeeImportRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postEmployeeImport = usePostEmployeeImportHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostEmployeeImportHook>>>,
		{ data: EmployeeImportRequest }
	> = (props) => {
		const { data } = props ?? {};

		return postEmployeeImport(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostEmployeeImportMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostEmployeeImportHook>>>
>;
export type PostEmployeeImportMutationBody = EmployeeImportRequest;
export type PostEmployeeImportMutationError = ErrorPayload;

export const usePostEmployeeImport = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostEmployeeImportHook>>>,
		TError,
		{ data: EmployeeImportRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostEmployeeImportHook>>>,
	TError,
	{ data: EmployeeImportRequest },
	TContext
> => {
	const mutationOptions = usePostEmployeeImportMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostInviteEmployeeHook = () => {
	const postInviteEmployee = useCustomInstance<Payload>();

	return useCallback(
		(inviteEmployee: InviteEmployee, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postInviteEmployee(
				{
					url: `/api/employees/invite`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: inviteEmployee,
				},
				options,
			),
		[postInviteEmployee],
	);
};

export const usePostInviteEmployeeMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostInviteEmployeeHook>>>,
		TError,
		{ data: InviteEmployee },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostInviteEmployeeHook>>>,
	TError,
	{ data: InviteEmployee },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postInviteEmployee = usePostInviteEmployeeHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostInviteEmployeeHook>>>,
		{ data: InviteEmployee }
	> = (props) => {
		const { data } = props ?? {};

		return postInviteEmployee(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostInviteEmployeeMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostInviteEmployeeHook>>>
>;
export type PostInviteEmployeeMutationBody = InviteEmployee;
export type PostInviteEmployeeMutationError = ErrorPayload;

export const usePostInviteEmployee = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostInviteEmployeeHook>>>,
		TError,
		{ data: InviteEmployee },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostInviteEmployeeHook>>>,
	TError,
	{ data: InviteEmployee },
	TContext
> => {
	const mutationOptions = usePostInviteEmployeeMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetMonthlyHoursOfAllEmployeesHook = () => {
	const getMonthlyHoursOfAllEmployees = useCustomInstance<DailyHoursWithTotalHours>();

	return useCallback(
		(
			params: GetMonthlyHoursOfAllEmployeesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) =>
			getMonthlyHoursOfAllEmployees(
				{ url: `/api/employees/monthlyHours`, method: 'GET', params, signal },
				options,
			),
		[getMonthlyHoursOfAllEmployees],
	);
};

export const getGetMonthlyHoursOfAllEmployeesQueryKey = (params: GetMonthlyHoursOfAllEmployeesParams) =>
	[`/api/employees/monthlyHours`, ...(params ? [params] : [])] as const;

export const useGetMonthlyHoursOfAllEmployeesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlyHoursOfAllEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetMonthlyHoursOfAllEmployeesQueryKey(params);

	const getMonthlyHoursOfAllEmployees = useGetMonthlyHoursOfAllEmployeesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>> = ({
		signal,
	}) => getMonthlyHoursOfAllEmployees(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetMonthlyHoursOfAllEmployeesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>
>;
export type GetMonthlyHoursOfAllEmployeesQueryError = ErrorPayload;

export function useGetMonthlyHoursOfAllEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlyHoursOfAllEmployeesParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMonthlyHoursOfAllEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlyHoursOfAllEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMonthlyHoursOfAllEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlyHoursOfAllEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMonthlyHoursOfAllEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlyHoursOfAllEmployeesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlyHoursOfAllEmployeesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetMonthlyHoursOfAllEmployeesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetMonthlySpendsHook = () => {
	const getMonthlySpends = useCustomInstance<DailyEarningsWithTotalEarnings>();

	return useCallback(
		(
			params: GetMonthlySpendsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getMonthlySpends({ url: `/api/employees/monthlySpends`, method: 'GET', params, signal }, options),
		[getMonthlySpends],
	);
};

export const getGetMonthlySpendsQueryKey = (params: GetMonthlySpendsParams) =>
	[`/api/employees/monthlySpends`, ...(params ? [params] : [])] as const;

export const useGetMonthlySpendsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlySpendsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetMonthlySpendsQueryKey(params);

	const getMonthlySpends = useGetMonthlySpendsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>> = ({ signal }) =>
		getMonthlySpends(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetMonthlySpendsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>>;
export type GetMonthlySpendsQueryError = ErrorPayload;

export function useGetMonthlySpends<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlySpendsParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMonthlySpends<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlySpendsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMonthlySpends<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlySpendsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMonthlySpends<
	TData = Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>,
	TError = ErrorPayload,
>(
	params: GetMonthlySpendsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMonthlySpendsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetMonthlySpendsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetDataForReviewTableHook = () => {
	const getDataForReviewTable = useCustomInstance<ReviewTableResponse>();

	return useCallback(
		(
			params: GetDataForReviewTableParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getDataForReviewTable({ url: `/api/employees/reviewTable`, method: 'GET', params, signal }, options),
		[getDataForReviewTable],
	);
};

export const getGetDataForReviewTableQueryKey = (params: GetDataForReviewTableParams) =>
	[`/api/employees/reviewTable`, ...(params ? [params] : [])] as const;

export const useGetDataForReviewTableQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
	TError = ErrorPayload,
>(
	params: GetDataForReviewTableParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetDataForReviewTableQueryKey(params);

	const getDataForReviewTable = useGetDataForReviewTableHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>> = ({ signal }) =>
		getDataForReviewTable(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetDataForReviewTableQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>
>;
export type GetDataForReviewTableQueryError = ErrorPayload;

export function useGetDataForReviewTable<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
	TError = ErrorPayload,
>(
	params: GetDataForReviewTableParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetDataForReviewTable<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
	TError = ErrorPayload,
>(
	params: GetDataForReviewTableParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetDataForReviewTable<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
	TError = ErrorPayload,
>(
	params: GetDataForReviewTableParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetDataForReviewTable<
	TData = Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>,
	TError = ErrorPayload,
>(
	params: GetDataForReviewTableParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDataForReviewTableHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetDataForReviewTableQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostEmployeeHook = () => {
	const postEmployee = useCustomInstance<Employee>();

	return useCallback(
		(createEmployee: CreateEmployee, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postEmployee(
				{
					url: `/api/employees`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createEmployee,
				},
				options,
			),
		[postEmployee],
	);
};

export const usePostEmployeeMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostEmployeeHook>>>,
		TError,
		{ data: CreateEmployee },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostEmployeeHook>>>,
	TError,
	{ data: CreateEmployee },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postEmployee = usePostEmployeeHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostEmployeeHook>>>,
		{ data: CreateEmployee }
	> = (props) => {
		const { data } = props ?? {};

		return postEmployee(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostEmployeeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostEmployeeHook>>>>;
export type PostEmployeeMutationBody = CreateEmployee;
export type PostEmployeeMutationError = ErrorPayload;

export const usePostEmployee = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostEmployeeHook>>>,
		TError,
		{ data: CreateEmployee },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostEmployeeHook>>>,
	TError,
	{ data: CreateEmployee },
	TContext
> => {
	const mutationOptions = usePostEmployeeMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetEmployeesHook = () => {
	const getEmployees = useCustomInstance<EmployeeWithWorkSchedule[]>();

	return useCallback(
		(
			params?: GetEmployeesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getEmployees({ url: `/api/employees`, method: 'GET', params, signal }, options),
		[getEmployees],
	);
};

export const getGetEmployeesQueryKey = (params?: GetEmployeesParams) =>
	[`/api/employees`, ...(params ? [params] : [])] as const;

export const useGetEmployeesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetEmployeesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetEmployeesQueryKey(params);

	const getEmployees = useGetEmployeesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>> = ({ signal }) =>
		getEmployees(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetEmployeesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>>;
export type GetEmployeesQueryError = ErrorPayload;

export function useGetEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetEmployeesParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetEmployeesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetEmployeesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetEmployees<
	TData = Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetEmployeesParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEmployeesHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetEmployeesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useMarkAllGroupedNotificationsReadHook = () => {
	const markAllGroupedNotificationsRead = useCustomInstance<GroupedNotification>();

	return useCallback(
		(groupedNotification: GroupedNotification, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			markAllGroupedNotificationsRead(
				{
					url: `/api/groupedNotifications/markAllRead`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: groupedNotification,
				},
				options,
			),
		[markAllGroupedNotificationsRead],
	);
};

export const useMarkAllGroupedNotificationsReadMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useMarkAllGroupedNotificationsReadHook>>>,
		TError,
		{ data: GroupedNotification },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useMarkAllGroupedNotificationsReadHook>>>,
	TError,
	{ data: GroupedNotification },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const markAllGroupedNotificationsRead = useMarkAllGroupedNotificationsReadHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useMarkAllGroupedNotificationsReadHook>>>,
		{ data: GroupedNotification }
	> = (props) => {
		const { data } = props ?? {};

		return markAllGroupedNotificationsRead(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MarkAllGroupedNotificationsReadMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useMarkAllGroupedNotificationsReadHook>>>
>;
export type MarkAllGroupedNotificationsReadMutationBody = GroupedNotification;
export type MarkAllGroupedNotificationsReadMutationError = ErrorPayload;

export const useMarkAllGroupedNotificationsRead = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useMarkAllGroupedNotificationsReadHook>>>,
		TError,
		{ data: GroupedNotification },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useMarkAllGroupedNotificationsReadHook>>>,
	TError,
	{ data: GroupedNotification },
	TContext
> => {
	const mutationOptions = useMarkAllGroupedNotificationsReadMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetGroupedNotificationsHook = () => {
	const getGroupedNotifications = useCustomInstance<GroupedNotification>();

	return useCallback(
		(options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getGroupedNotifications({ url: `/api/groupedNotifications`, method: 'GET', signal }, options),
		[getGroupedNotifications],
	);
};

export const getGetGroupedNotificationsQueryKey = () => [`/api/groupedNotifications`] as const;

export const useGetGroupedNotificationsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>, TError, TData>
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetGroupedNotificationsQueryKey();

	const getGroupedNotifications = useGetGroupedNotificationsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>> = ({
		signal,
	}) => getGroupedNotifications(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetGroupedNotificationsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>
>;
export type GetGroupedNotificationsQueryError = ErrorPayload;

export function useGetGroupedNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
	TError = ErrorPayload,
>(options: {
	query: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>, TError, TData>
	> &
		Pick<
			DefinedInitialDataOptions<
				Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
				TError,
				TData
			>,
			'initialData'
		>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGroupedNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>, TError, TData>
	> &
		Pick<
			UndefinedInitialDataOptions<
				Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
				TError,
				TData
			>,
			'initialData'
		>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGroupedNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>, TError, TData>
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetGroupedNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGroupedNotificationsHook>>>, TError, TData>
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetGroupedNotificationsQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateInvitationByIdHook = () => {
	const updateInvitationById = useCustomInstance<Invitation>();

	return useCallback(
		(
			id: string,
			updateInvitation: UpdateInvitation,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			updateInvitationById(
				{
					url: `/api/invitations/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateInvitation,
				},
				options,
			),
		[updateInvitationById],
	);
};

export const useUpdateInvitationByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateInvitationByIdHook>>>,
		TError,
		{ id: string; data: UpdateInvitation },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateInvitationByIdHook>>>,
	TError,
	{ id: string; data: UpdateInvitation },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateInvitationById = useUpdateInvitationByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateInvitationByIdHook>>>,
		{ id: string; data: UpdateInvitation }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateInvitationById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateInvitationByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateInvitationByIdHook>>>
>;
export type UpdateInvitationByIdMutationBody = UpdateInvitation;
export type UpdateInvitationByIdMutationError = ErrorPayload;

export const useUpdateInvitationById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateInvitationByIdHook>>>,
		TError,
		{ id: string; data: UpdateInvitation },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateInvitationByIdHook>>>,
	TError,
	{ id: string; data: UpdateInvitation },
	TContext
> => {
	const mutationOptions = useUpdateInvitationByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteInvitationByIdHook = () => {
	const deleteInvitationById = useCustomInstance<Invitation>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteInvitationById({ url: `/api/invitations/${id}`, method: 'DELETE' }, options),
		[deleteInvitationById],
	);
};

export const useDeleteInvitationByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteInvitationByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteInvitationByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteInvitationById = useDeleteInvitationByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteInvitationByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteInvitationById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteInvitationByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteInvitationByIdHook>>>
>;

export type DeleteInvitationByIdMutationError = ErrorPayload;

export const useDeleteInvitationById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteInvitationByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteInvitationByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteInvitationByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetInvitationByIdHook = () => {
	const getInvitationById = useCustomInstance<Invitation>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getInvitationById({ url: `/api/invitations/${id}`, method: 'GET', signal }, options),
		[getInvitationById],
	);
};

export const getGetInvitationByIdQueryKey = (id: string) => [`/api/invitations/${id}`] as const;

export const useGetInvitationByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetInvitationByIdQueryKey(id);

	const getInvitationById = useGetInvitationByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>> = ({ signal }) =>
		getInvitationById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetInvitationByIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>
>;
export type GetInvitationByIdQueryError = ErrorPayload;

export function useGetInvitationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInvitationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInvitationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetInvitationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetInvitationByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostInvitationHook = () => {
	const postInvitation = useCustomInstance<Invitation>();

	return useCallback(
		(createInvitation: CreateInvitation, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postInvitation(
				{
					url: `/api/invitations`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createInvitation,
				},
				options,
			),
		[postInvitation],
	);
};

export const usePostInvitationMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostInvitationHook>>>,
		TError,
		{ data: CreateInvitation },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostInvitationHook>>>,
	TError,
	{ data: CreateInvitation },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postInvitation = usePostInvitationHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostInvitationHook>>>,
		{ data: CreateInvitation }
	> = (props) => {
		const { data } = props ?? {};

		return postInvitation(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostInvitationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostInvitationHook>>>>;
export type PostInvitationMutationBody = CreateInvitation;
export type PostInvitationMutationError = ErrorPayload;

export const usePostInvitation = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostInvitationHook>>>,
		TError,
		{ data: CreateInvitation },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostInvitationHook>>>,
	TError,
	{ data: CreateInvitation },
	TContext
> => {
	const mutationOptions = usePostInvitationMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetInvitationsHook = () => {
	const getInvitations = useCustomInstance<Invitation[]>();

	return useCallback(
		(
			params?: GetInvitationsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getInvitations({ url: `/api/invitations`, method: 'GET', params, signal }, options),
		[getInvitations],
	);
};

export const getGetInvitationsQueryKey = (params?: GetInvitationsParams) =>
	[`/api/invitations`, ...(params ? [params] : [])] as const;

export const useGetInvitationsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetInvitationsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetInvitationsQueryKey(params);

	const getInvitations = useGetInvitationsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>> = ({ signal }) =>
		getInvitations(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetInvitationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>>;
export type GetInvitationsQueryError = ErrorPayload;

export function useGetInvitations<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetInvitationsParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInvitations<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetInvitationsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInvitations<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetInvitationsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetInvitations<
	TData = Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetInvitationsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInvitationsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetInvitationsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateJobByIdHook = () => {
	const updateJobById = useCustomInstance<Job>();

	return useCallback(
		(id: string, updateJob: UpdateJob, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateJobById(
				{
					url: `/api/jobs/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateJob,
				},
				options,
			),
		[updateJobById],
	);
};

export const useUpdateJobByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateJobByIdHook>>>,
		TError,
		{ id: string; data: UpdateJob },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateJobByIdHook>>>,
	TError,
	{ id: string; data: UpdateJob },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateJobById = useUpdateJobByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateJobByIdHook>>>,
		{ id: string; data: UpdateJob }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateJobById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateJobByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateJobByIdHook>>>>;
export type UpdateJobByIdMutationBody = UpdateJob;
export type UpdateJobByIdMutationError = ErrorPayload;

export const useUpdateJobById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateJobByIdHook>>>,
		TError,
		{ id: string; data: UpdateJob },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateJobByIdHook>>>,
	TError,
	{ id: string; data: UpdateJob },
	TContext
> => {
	const mutationOptions = useUpdateJobByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteJobByIdHook = () => {
	const deleteJobById = useCustomInstance<Job>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteJobById({ url: `/api/jobs/${id}`, method: 'DELETE' }, options),
		[deleteJobById],
	);
};

export const useDeleteJobByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteJobByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteJobByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteJobById = useDeleteJobByIdHook();

	const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteJobByIdHook>>>, { id: string }> = (
		props,
	) => {
		const { id } = props ?? {};

		return deleteJobById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteJobByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteJobByIdHook>>>>;

export type DeleteJobByIdMutationError = ErrorPayload;

export const useDeleteJobById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteJobByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteJobByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteJobByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetJobByIdHook = () => {
	const getJobById = useCustomInstance<Job>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getJobById({ url: `/api/jobs/${id}`, method: 'GET', signal }, options),
		[getJobById],
	);
};

export const getGetJobByIdQueryKey = (id: string) => [`/api/jobs/${id}`] as const;

export const useGetJobByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobByIdQueryKey(id);

	const getJobById = useGetJobByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>> = ({ signal }) =>
		getJobById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>>;
export type GetJobByIdQueryError = ErrorPayload;

export function useGetJobById<TData = Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError = ErrorPayload>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobById<TData = Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError = ErrorPayload>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobById<TData = Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError = ErrorPayload>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobById<TData = Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError = ErrorPayload>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetActiveJobsHook = () => {
	const getActiveJobs = useCustomInstance<ActiveJobs>();

	return useCallback(
		(
			limit: string,
			params?: GetActiveJobsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getActiveJobs({ url: `/api/jobs/active/${limit}`, method: 'GET', params, signal }, options),
		[getActiveJobs],
	);
};

export const getGetActiveJobsQueryKey = (limit: string, params?: GetActiveJobsParams) =>
	[`/api/jobs/active/${limit}`, ...(params ? [params] : [])] as const;

export const useGetActiveJobsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
	TError = ErrorPayload,
>(
	limit: string,
	params?: GetActiveJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetActiveJobsQueryKey(limit, params);

	const getActiveJobs = useGetActiveJobsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>> = ({ signal }) =>
		getActiveJobs(limit, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!limit, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetActiveJobsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>>;
export type GetActiveJobsQueryError = ErrorPayload;

export function useGetActiveJobs<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
	TError = ErrorPayload,
>(
	limit: string,
	params: undefined | GetActiveJobsParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetActiveJobs<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
	TError = ErrorPayload,
>(
	limit: string,
	params?: GetActiveJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetActiveJobs<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
	TError = ErrorPayload,
>(
	limit: string,
	params?: GetActiveJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetActiveJobs<
	TData = Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>,
	TError = ErrorPayload,
>(
	limit: string,
	params?: GetActiveJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveJobsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetActiveJobsQueryOptions(limit, params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostJobsBatchHook = () => {
	const postJobsBatch = useCustomInstance<Job[]>();

	return useCallback(
		(updateJobsBatch: UpdateJobsBatch, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postJobsBatch(
				{
					url: `/api/jobs/batch`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: updateJobsBatch,
				},
				options,
			),
		[postJobsBatch],
	);
};

export const usePostJobsBatchMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostJobsBatchHook>>>,
		TError,
		{ data: UpdateJobsBatch },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostJobsBatchHook>>>,
	TError,
	{ data: UpdateJobsBatch },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postJobsBatch = usePostJobsBatchHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostJobsBatchHook>>>,
		{ data: UpdateJobsBatch }
	> = (props) => {
		const { data } = props ?? {};

		return postJobsBatch(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostJobsBatchMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostJobsBatchHook>>>>;
export type PostJobsBatchMutationBody = UpdateJobsBatch;
export type PostJobsBatchMutationError = ErrorPayload;

export const usePostJobsBatch = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostJobsBatchHook>>>,
		TError,
		{ data: UpdateJobsBatch },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostJobsBatchHook>>>,
	TError,
	{ data: UpdateJobsBatch },
	TContext
> => {
	const mutationOptions = usePostJobsBatchMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetJobsCostsHook = () => {
	const getJobsCosts = useCustomInstance<JobsWithCosts>();

	return useCallback(
		(
			params: GetJobsCostsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getJobsCosts({ url: `/api/jobs/costs`, method: 'GET', params, signal }, options),
		[getJobsCosts],
	);
};

export const getGetJobsCostsQueryKey = (params: GetJobsCostsParams) =>
	[`/api/jobs/costs`, ...(params ? [params] : [])] as const;

export const useGetJobsCostsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsCostsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobsCostsQueryKey(params);

	const getJobsCosts = useGetJobsCostsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>> = ({ signal }) =>
		getJobsCosts(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobsCostsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>>;
export type GetJobsCostsQueryError = ErrorPayload;

export function useGetJobsCosts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsCostsParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsCosts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsCostsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsCosts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsCostsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobsCosts<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsCostsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsCostsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobsCostsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetJobsProfitabilityPercentageHook = () => {
	const getJobsProfitabilityPercentage = useCustomInstance<JobsProfitabilityPercentage>();

	return useCallback(
		(
			params: GetJobsProfitabilityPercentageParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getJobsProfitabilityPercentage({ url: `/api/jobs/profitability`, method: 'GET', params, signal }, options),
		[getJobsProfitabilityPercentage],
	);
};

export const getGetJobsProfitabilityPercentageQueryKey = (params: GetJobsProfitabilityPercentageParams) =>
	[`/api/jobs/profitability`, ...(params ? [params] : [])] as const;

export const useGetJobsProfitabilityPercentageQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsProfitabilityPercentageParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
				TError,
				TData
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobsProfitabilityPercentageQueryKey(params);

	const getJobsProfitabilityPercentage = useGetJobsProfitabilityPercentageHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>> = ({
		signal,
	}) => getJobsProfitabilityPercentage(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobsProfitabilityPercentageQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>
>;
export type GetJobsProfitabilityPercentageQueryError = ErrorPayload;

export function useGetJobsProfitabilityPercentage<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsProfitabilityPercentageParams,
	options: {
		query: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
				TError,
				TData
			>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsProfitabilityPercentage<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsProfitabilityPercentageParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
				TError,
				TData
			>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsProfitabilityPercentage<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsProfitabilityPercentageParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
				TError,
				TData
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobsProfitabilityPercentage<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsProfitabilityPercentageParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsProfitabilityPercentageHook>>>,
				TError,
				TData
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobsProfitabilityPercentageQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostJobHook = () => {
	const postJob = useCustomInstance<Job>();

	return useCallback(
		(createJob: CreateJob, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postJob(
				{ url: `/api/jobs`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createJob },
				options,
			),
		[postJob],
	);
};

export const usePostJobMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostJobHook>>>,
		TError,
		{ data: CreateJob },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostJobHook>>>,
	TError,
	{ data: CreateJob },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postJob = usePostJobHook();

	const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostJobHook>>>, { data: CreateJob }> = (
		props,
	) => {
		const { data } = props ?? {};

		return postJob(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostJobMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostJobHook>>>>;
export type PostJobMutationBody = CreateJob;
export type PostJobMutationError = ErrorPayload;

export const usePostJob = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostJobHook>>>,
		TError,
		{ data: CreateJob },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostJobHook>>>,
	TError,
	{ data: CreateJob },
	TContext
> => {
	const mutationOptions = usePostJobMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetJobsHook = () => {
	const getJobs = useCustomInstance<JobWithRelations[]>();

	return useCallback(
		(
			params: GetJobsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getJobs({ url: `/api/jobs`, method: 'GET', params, signal }, options),
		[getJobs],
	);
};

export const getGetJobsQueryKey = (params: GetJobsParams) => [`/api/jobs`, ...(params ? [params] : [])] as const;

export const useGetJobsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobsQueryKey(params);

	const getJobs = useGetJobsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>> = ({ signal }) =>
		getJobs(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>>;
export type GetJobsQueryError = ErrorPayload;

export function useGetJobs<TData = Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError = ErrorPayload>(
	params: GetJobsParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobs<TData = Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError = ErrorPayload>(
	params: GetJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobs<TData = Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError = ErrorPayload>(
	params: GetJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobs<TData = Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError = ErrorPayload>(
	params: GetJobsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetJobsWithTimesHook = () => {
	const getJobsWithTimes = useCustomInstance<GetJobWithRelationsPaginatedResponse>();

	return useCallback(
		(
			params: GetJobsWithTimesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getJobsWithTimes({ url: `/api/jobs/table`, method: 'GET', params, signal }, options),
		[getJobsWithTimes],
	);
};

export const getGetJobsWithTimesQueryKey = (params: GetJobsWithTimesParams) =>
	[`/api/jobs/table`, ...(params ? [params] : [])] as const;

export const useGetJobsWithTimesInfiniteQueryOptions = <
	TData = InfiniteData<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		GetJobsWithTimesParams['cursor']
	>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				QueryKey,
				GetJobsWithTimesParams['cursor']
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobsWithTimesQueryKey(params);

	const getJobsWithTimes = useGetJobsWithTimesHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		QueryKey,
		GetJobsWithTimesParams['cursor']
	> = ({ signal, pageParam }) =>
		getJobsWithTimes({ ...params, cursor: pageParam || params?.cursor }, requestOptions, signal);

	return { queryKey, queryFn, keepPreviousData: true, ...queryOptions } as UseInfiniteQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		TError,
		TData,
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		QueryKey,
		GetJobsWithTimesParams['cursor']
	> & { queryKey: QueryKey };
};

export type GetJobsWithTimesInfiniteQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>
>;
export type GetJobsWithTimesInfiniteQueryError = ErrorPayload;

export function useGetJobsWithTimesInfinite<
	TData = InfiniteData<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		GetJobsWithTimesParams['cursor']
	>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options: {
		query: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				QueryKey,
				GetJobsWithTimesParams['cursor']
			>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
					TError,
					TData,
					QueryKey
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsWithTimesInfinite<
	TData = InfiniteData<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		GetJobsWithTimesParams['cursor']
	>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				QueryKey,
				GetJobsWithTimesParams['cursor']
			>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
					TError,
					TData,
					QueryKey
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsWithTimesInfinite<
	TData = InfiniteData<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		GetJobsWithTimesParams['cursor']
	>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				QueryKey,
				GetJobsWithTimesParams['cursor']
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobsWithTimesInfinite<
	TData = InfiniteData<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		GetJobsWithTimesParams['cursor']
	>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseInfiniteQueryOptions<
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				TError,
				TData,
				Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
				QueryKey,
				GetJobsWithTimesParams['cursor']
			>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobsWithTimesInfiniteQueryOptions(params, options);

	const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetJobsWithTimesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetJobsWithTimesQueryKey(params);

	const getJobsWithTimes = useGetJobsWithTimesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>> = ({ signal }) =>
		getJobsWithTimes(params, requestOptions, signal);

	return { queryKey, queryFn, keepPreviousData: true, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetJobsWithTimesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>>;
export type GetJobsWithTimesQueryError = ErrorPayload;

export function useGetJobsWithTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsWithTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetJobsWithTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetJobsWithTimes<
	TData = Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>,
	TError = ErrorPayload,
>(
	params: GetJobsWithTimesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobsWithTimesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetJobsWithTimesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetLoggedUserHook = () => {
	const getLoggedUser = useCustomInstance<User>();

	return useCallback(
		(options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getLoggedUser({ url: `/api/loggedUser`, method: 'GET', signal }, options),
		[getLoggedUser],
	);
};

export const getGetLoggedUserQueryKey = () => [`/api/loggedUser`] as const;

export const useGetLoggedUserQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetLoggedUserQueryKey();

	const getLoggedUser = useGetLoggedUserHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>> = ({ signal }) =>
		getLoggedUser(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetLoggedUserQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>>;
export type GetLoggedUserQueryError = ErrorPayload;

export function useGetLoggedUser<
	TData = Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>,
	TError = ErrorPayload,
>(options: {
	query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>> &
		Pick<
			DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>,
			'initialData'
		>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLoggedUser<
	TData = Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>> &
		Pick<
			UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>,
			'initialData'
		>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLoggedUser<
	TData = Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetLoggedUser<
	TData = Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoggedUserHook>>>, TError, TData>>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetLoggedUserQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateNotificationByIdHook = () => {
	const updateNotificationById = useCustomInstance<Notification>();

	return useCallback(
		(
			id: string,
			updateNotification: UpdateNotification,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			updateNotificationById(
				{
					url: `/api/notifications/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateNotification,
				},
				options,
			),
		[updateNotificationById],
	);
};

export const useUpdateNotificationByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateNotificationByIdHook>>>,
		TError,
		{ id: string; data: UpdateNotification },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateNotificationByIdHook>>>,
	TError,
	{ id: string; data: UpdateNotification },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateNotificationById = useUpdateNotificationByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateNotificationByIdHook>>>,
		{ id: string; data: UpdateNotification }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateNotificationById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateNotificationByIdHook>>>
>;
export type UpdateNotificationByIdMutationBody = UpdateNotification;
export type UpdateNotificationByIdMutationError = ErrorPayload;

export const useUpdateNotificationById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateNotificationByIdHook>>>,
		TError,
		{ id: string; data: UpdateNotification },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateNotificationByIdHook>>>,
	TError,
	{ id: string; data: UpdateNotification },
	TContext
> => {
	const mutationOptions = useUpdateNotificationByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteNotificationByIdHook = () => {
	const deleteNotificationById = useCustomInstance<Notification>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteNotificationById({ url: `/api/notifications/${id}`, method: 'DELETE' }, options),
		[deleteNotificationById],
	);
};

export const useDeleteNotificationByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteNotificationByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteNotificationByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteNotificationById = useDeleteNotificationByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteNotificationByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteNotificationById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteNotificationByIdHook>>>
>;

export type DeleteNotificationByIdMutationError = ErrorPayload;

export const useDeleteNotificationById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteNotificationByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteNotificationByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteNotificationByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetNotificationByIdHook = () => {
	const getNotificationById = useCustomInstance<Notification>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getNotificationById({ url: `/api/notifications/${id}`, method: 'GET', signal }, options),
		[getNotificationById],
	);
};

export const getGetNotificationByIdQueryKey = (id: string) => [`/api/notifications/${id}`] as const;

export const useGetNotificationByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetNotificationByIdQueryKey(id);

	const getNotificationById = useGetNotificationByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>> = ({ signal }) =>
		getNotificationById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetNotificationByIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>
>;
export type GetNotificationByIdQueryError = ErrorPayload;

export function useGetNotificationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNotificationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNotificationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetNotificationById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetNotificationByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostNotificationHook = () => {
	const postNotification = useCustomInstance<Notification>();

	return useCallback(
		(createNotification: CreateNotification, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postNotification(
				{
					url: `/api/notifications`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createNotification,
				},
				options,
			),
		[postNotification],
	);
};

export const usePostNotificationMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostNotificationHook>>>,
		TError,
		{ data: CreateNotification },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostNotificationHook>>>,
	TError,
	{ data: CreateNotification },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postNotification = usePostNotificationHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostNotificationHook>>>,
		{ data: CreateNotification }
	> = (props) => {
		const { data } = props ?? {};

		return postNotification(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostNotificationMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostNotificationHook>>>
>;
export type PostNotificationMutationBody = CreateNotification;
export type PostNotificationMutationError = ErrorPayload;

export const usePostNotification = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostNotificationHook>>>,
		TError,
		{ data: CreateNotification },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostNotificationHook>>>,
	TError,
	{ data: CreateNotification },
	TContext
> => {
	const mutationOptions = usePostNotificationMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetNotificationsHook = () => {
	const getNotifications = useCustomInstance<NotificationWithRelations[]>();

	return useCallback(
		(
			params?: GetNotificationsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getNotifications({ url: `/api/notifications`, method: 'GET', params, signal }, options),
		[getNotifications],
	);
};

export const getGetNotificationsQueryKey = (params?: GetNotificationsParams) =>
	[`/api/notifications`, ...(params ? [params] : [])] as const;

export const useGetNotificationsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetNotificationsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetNotificationsQueryKey(params);

	const getNotifications = useGetNotificationsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>> = ({ signal }) =>
		getNotifications(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetNotificationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>>;
export type GetNotificationsQueryError = ErrorPayload;

export function useGetNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetNotificationsParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetNotificationsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetNotificationsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetNotifications<
	TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetNotificationsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetNotificationsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateOwnerByIdHook = () => {
	const updateOwnerById = useCustomInstance<Owner>();

	return useCallback(
		(id: string, updateOwner: UpdateOwner, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateOwnerById(
				{
					url: `/api/owners/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateOwner,
				},
				options,
			),
		[updateOwnerById],
	);
};

export const useUpdateOwnerByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateOwnerByIdHook>>>,
		TError,
		{ id: string; data: UpdateOwner },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateOwnerByIdHook>>>,
	TError,
	{ id: string; data: UpdateOwner },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateOwnerById = useUpdateOwnerByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateOwnerByIdHook>>>,
		{ id: string; data: UpdateOwner }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateOwnerById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateOwnerByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOwnerByIdHook>>>>;
export type UpdateOwnerByIdMutationBody = UpdateOwner;
export type UpdateOwnerByIdMutationError = ErrorPayload;

export const useUpdateOwnerById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateOwnerByIdHook>>>,
		TError,
		{ id: string; data: UpdateOwner },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateOwnerByIdHook>>>,
	TError,
	{ id: string; data: UpdateOwner },
	TContext
> => {
	const mutationOptions = useUpdateOwnerByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteOwnerByIdHook = () => {
	const deleteOwnerById = useCustomInstance<Owner>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteOwnerById({ url: `/api/owners/${id}`, method: 'DELETE' }, options),
		[deleteOwnerById],
	);
};

export const useDeleteOwnerByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteOwnerByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteOwnerByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteOwnerById = useDeleteOwnerByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteOwnerByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteOwnerById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteOwnerByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteOwnerByIdHook>>>>;

export type DeleteOwnerByIdMutationError = ErrorPayload;

export const useDeleteOwnerById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteOwnerByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteOwnerByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteOwnerByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetOwnerByIdHook = () => {
	const getOwnerById = useCustomInstance<Owner>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getOwnerById({ url: `/api/owners/${id}`, method: 'GET', signal }, options),
		[getOwnerById],
	);
};

export const getGetOwnerByIdQueryKey = (id: string) => [`/api/owners/${id}`] as const;

export const useGetOwnerByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOwnerByIdQueryKey(id);

	const getOwnerById = useGetOwnerByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>> = ({ signal }) =>
		getOwnerById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetOwnerByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>>;
export type GetOwnerByIdQueryError = ErrorPayload;

export function useGetOwnerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOwnerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOwnerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOwnerById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetOwnerByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostOwnerHook = () => {
	const postOwner = useCustomInstance<Owner>();

	return useCallback(
		(createOwner: CreateOwner, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postOwner(
				{
					url: `/api/owners`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createOwner,
				},
				options,
			),
		[postOwner],
	);
};

export const usePostOwnerMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostOwnerHook>>>,
		TError,
		{ data: CreateOwner },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostOwnerHook>>>,
	TError,
	{ data: CreateOwner },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postOwner = usePostOwnerHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostOwnerHook>>>,
		{ data: CreateOwner }
	> = (props) => {
		const { data } = props ?? {};

		return postOwner(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostOwnerMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOwnerHook>>>>;
export type PostOwnerMutationBody = CreateOwner;
export type PostOwnerMutationError = ErrorPayload;

export const usePostOwner = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostOwnerHook>>>,
		TError,
		{ data: CreateOwner },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostOwnerHook>>>,
	TError,
	{ data: CreateOwner },
	TContext
> => {
	const mutationOptions = usePostOwnerMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetOwnersWithRoleByUserIdHook = () => {
	const getOwnersWithRoleByUserId = useCustomInstance<OwnerWithRoles[]>();

	return useCallback(
		(options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getOwnersWithRoleByUserId({ url: `/api/owners`, method: 'GET', signal }, options),
		[getOwnersWithRoleByUserId],
	);
};

export const getGetOwnersWithRoleByUserIdQueryKey = () => [`/api/owners`] as const;

export const useGetOwnersWithRoleByUserIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>, TError, TData>
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOwnersWithRoleByUserIdQueryKey();

	const getOwnersWithRoleByUserId = useGetOwnersWithRoleByUserIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>> = ({
		signal,
	}) => getOwnersWithRoleByUserId(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetOwnersWithRoleByUserIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>
>;
export type GetOwnersWithRoleByUserIdQueryError = ErrorPayload;

export function useGetOwnersWithRoleByUserId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
	TError = ErrorPayload,
>(options: {
	query: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>, TError, TData>
	> &
		Pick<
			DefinedInitialDataOptions<
				Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
				TError,
				TData
			>,
			'initialData'
		>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOwnersWithRoleByUserId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>, TError, TData>
	> &
		Pick<
			UndefinedInitialDataOptions<
				Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
				TError,
				TData
			>,
			'initialData'
		>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOwnersWithRoleByUserId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>, TError, TData>
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOwnersWithRoleByUserId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>,
	TError = ErrorPayload,
>(options?: {
	query?: Partial<
		UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnersWithRoleByUserIdHook>>>, TError, TData>
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetOwnersWithRoleByUserIdQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetQrCodeByIdAndOwnerHook = () => {
	const getQrCodeByIdAndOwner = useCustomInstance<QRCodeResponse>();

	return useCallback(
		(
			qrId: string,
			ownerId: string,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getQrCodeByIdAndOwner({ url: `/api/qr/${qrId}/${ownerId}`, method: 'GET', signal }, options),
		[getQrCodeByIdAndOwner],
	);
};

export const getGetQrCodeByIdAndOwnerQueryKey = (qrId: string, ownerId: string) =>
	[`/api/qr/${qrId}/${ownerId}`] as const;

export const useGetQrCodeByIdAndOwnerQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
	TError = ErrorPayload,
>(
	qrId: string,
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetQrCodeByIdAndOwnerQueryKey(qrId, ownerId);

	const getQrCodeByIdAndOwner = useGetQrCodeByIdAndOwnerHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>> = ({ signal }) =>
		getQrCodeByIdAndOwner(qrId, ownerId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(qrId && ownerId), ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetQrCodeByIdAndOwnerQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>
>;
export type GetQrCodeByIdAndOwnerQueryError = ErrorPayload;

export function useGetQrCodeByIdAndOwner<
	TData = Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
	TError = ErrorPayload,
>(
	qrId: string,
	ownerId: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQrCodeByIdAndOwner<
	TData = Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
	TError = ErrorPayload,
>(
	qrId: string,
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetQrCodeByIdAndOwner<
	TData = Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
	TError = ErrorPayload,
>(
	qrId: string,
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetQrCodeByIdAndOwner<
	TData = Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>,
	TError = ErrorPayload,
>(
	qrId: string,
	ownerId: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQrCodeByIdAndOwnerHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetQrCodeByIdAndOwnerQueryOptions(qrId, ownerId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useConfirmQrScanHook = () => {
	const confirmQrScan = useCustomInstance<QRCodeResponse>();

	return useCallback(
		(
			ownerId: string,
			confirmQrCodeScanRequest: ConfirmQrCodeScanRequest,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			confirmQrScan(
				{
					url: `/api/qr/v2/${ownerId}/confirm`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: confirmQrCodeScanRequest,
				},
				options,
			),
		[confirmQrScan],
	);
};

export const useConfirmQrScanMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useConfirmQrScanHook>>>,
		TError,
		{ ownerId: string; data: ConfirmQrCodeScanRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useConfirmQrScanHook>>>,
	TError,
	{ ownerId: string; data: ConfirmQrCodeScanRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const confirmQrScan = useConfirmQrScanHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useConfirmQrScanHook>>>,
		{ ownerId: string; data: ConfirmQrCodeScanRequest }
	> = (props) => {
		const { ownerId, data } = props ?? {};

		return confirmQrScan(ownerId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ConfirmQrScanMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useConfirmQrScanHook>>>>;
export type ConfirmQrScanMutationBody = ConfirmQrCodeScanRequest;
export type ConfirmQrScanMutationError = ErrorPayload;

export const useConfirmQrScan = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useConfirmQrScanHook>>>,
		TError,
		{ ownerId: string; data: ConfirmQrCodeScanRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useConfirmQrScanHook>>>,
	TError,
	{ ownerId: string; data: ConfirmQrCodeScanRequest },
	TContext
> => {
	const mutationOptions = useConfirmQrScanMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useRegisterQrTransmitterHook = () => {
	const registerQrTransmitter = useCustomInstance<RegisterQrResponse>();

	return useCallback(
		(
			ownerId: string,
			registerQrTransmitterRequest: RegisterQrTransmitterRequest,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			registerQrTransmitter(
				{
					url: `/api/qr/v2/${ownerId}/register`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: registerQrTransmitterRequest,
				},
				options,
			),
		[registerQrTransmitter],
	);
};

export const useRegisterQrTransmitterMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useRegisterQrTransmitterHook>>>,
		TError,
		{ ownerId: string; data: RegisterQrTransmitterRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useRegisterQrTransmitterHook>>>,
	TError,
	{ ownerId: string; data: RegisterQrTransmitterRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const registerQrTransmitter = useRegisterQrTransmitterHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useRegisterQrTransmitterHook>>>,
		{ ownerId: string; data: RegisterQrTransmitterRequest }
	> = (props) => {
		const { ownerId, data } = props ?? {};

		return registerQrTransmitter(ownerId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type RegisterQrTransmitterMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useRegisterQrTransmitterHook>>>
>;
export type RegisterQrTransmitterMutationBody = RegisterQrTransmitterRequest;
export type RegisterQrTransmitterMutationError = ErrorPayload;

export const useRegisterQrTransmitter = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useRegisterQrTransmitterHook>>>,
		TError,
		{ ownerId: string; data: RegisterQrTransmitterRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useRegisterQrTransmitterHook>>>,
	TError,
	{ ownerId: string; data: RegisterQrTransmitterRequest },
	TContext
> => {
	const mutationOptions = useRegisterQrTransmitterMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useUpdateRequestByIdHook = () => {
	const updateRequestById = useCustomInstance<Request>();

	return useCallback(
		(id: string, updateRequest: UpdateRequest, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateRequestById(
				{
					url: `/api/requests/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateRequest,
				},
				options,
			),
		[updateRequestById],
	);
};

export const useUpdateRequestByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateRequestByIdHook>>>,
		TError,
		{ id: string; data: UpdateRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateRequestByIdHook>>>,
	TError,
	{ id: string; data: UpdateRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateRequestById = useUpdateRequestByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateRequestByIdHook>>>,
		{ id: string; data: UpdateRequest }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateRequestById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateRequestByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateRequestByIdHook>>>
>;
export type UpdateRequestByIdMutationBody = UpdateRequest;
export type UpdateRequestByIdMutationError = ErrorPayload;

export const useUpdateRequestById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateRequestByIdHook>>>,
		TError,
		{ id: string; data: UpdateRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateRequestByIdHook>>>,
	TError,
	{ id: string; data: UpdateRequest },
	TContext
> => {
	const mutationOptions = useUpdateRequestByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteRequestByIdHook = () => {
	const deleteRequestById = useCustomInstance<Request>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteRequestById({ url: `/api/requests/${id}`, method: 'DELETE' }, options),
		[deleteRequestById],
	);
};

export const useDeleteRequestByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteRequestByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteRequestByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteRequestById = useDeleteRequestByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteRequestByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteRequestById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteRequestByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteRequestByIdHook>>>
>;

export type DeleteRequestByIdMutationError = ErrorPayload;

export const useDeleteRequestById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteRequestByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteRequestByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteRequestByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetRequestByIdHook = () => {
	const getRequestById = useCustomInstance<Request>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getRequestById({ url: `/api/requests/${id}`, method: 'GET', signal }, options),
		[getRequestById],
	);
};

export const getGetRequestByIdQueryKey = (id: string) => [`/api/requests/${id}`] as const;

export const useGetRequestByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetRequestByIdQueryKey(id);

	const getRequestById = useGetRequestByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>> = ({ signal }) =>
		getRequestById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetRequestByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>>;
export type GetRequestByIdQueryError = ErrorPayload;

export function useGetRequestById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetRequestById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetRequestById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetRequestById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetRequestByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostRequestHook = () => {
	const postRequest = useCustomInstance<Request>();

	return useCallback(
		(createRequest: CreateRequest, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postRequest(
				{
					url: `/api/requests`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createRequest,
				},
				options,
			),
		[postRequest],
	);
};

export const usePostRequestMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostRequestHook>>>,
		TError,
		{ data: CreateRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostRequestHook>>>,
	TError,
	{ data: CreateRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postRequest = usePostRequestHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostRequestHook>>>,
		{ data: CreateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return postRequest(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostRequestHook>>>>;
export type PostRequestMutationBody = CreateRequest;
export type PostRequestMutationError = ErrorPayload;

export const usePostRequest = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostRequestHook>>>,
		TError,
		{ data: CreateRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostRequestHook>>>,
	TError,
	{ data: CreateRequest },
	TContext
> => {
	const mutationOptions = usePostRequestMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetRequestsHook = () => {
	const getRequests = useCustomInstance<RequestWithRelations[]>();

	return useCallback(
		(
			params?: GetRequestsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getRequests({ url: `/api/requests`, method: 'GET', params, signal }, options),
		[getRequests],
	);
};

export const getGetRequestsQueryKey = (params?: GetRequestsParams) =>
	[`/api/requests`, ...(params ? [params] : [])] as const;

export const useGetRequestsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetRequestsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetRequestsQueryKey(params);

	const getRequests = useGetRequestsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>> = ({ signal }) =>
		getRequests(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>>;
export type GetRequestsQueryError = ErrorPayload;

export function useGetRequests<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetRequestsParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetRequests<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetRequestsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetRequests<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetRequestsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetRequests<
	TData = Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>,
	TError = ErrorPayload,
>(
	params?: GetRequestsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestsHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetRequestsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetSearchResultsHook = () => {
	const getSearchResults = useCustomInstance<Search[]>();

	return useCallback(
		(
			params: GetSearchResultsParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getSearchResults({ url: `/api/search`, method: 'GET', params, signal }, options),
		[getSearchResults],
	);
};

export const getGetSearchResultsQueryKey = (params: GetSearchResultsParams) =>
	[`/api/search`, ...(params ? [params] : [])] as const;

export const useGetSearchResultsQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
	TError = ErrorPayload,
>(
	params: GetSearchResultsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetSearchResultsQueryKey(params);

	const getSearchResults = useGetSearchResultsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>> = ({ signal }) =>
		getSearchResults(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetSearchResultsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>>;
export type GetSearchResultsQueryError = ErrorPayload;

export function useGetSearchResults<
	TData = Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
	TError = ErrorPayload,
>(
	params: GetSearchResultsParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSearchResults<
	TData = Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
	TError = ErrorPayload,
>(
	params: GetSearchResultsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSearchResults<
	TData = Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
	TError = ErrorPayload,
>(
	params: GetSearchResultsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetSearchResults<
	TData = Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>,
	TError = ErrorPayload,
>(
	params: GetSearchResultsParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchResultsHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetSearchResultsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostSendEmailHook = () => {
	const postSendEmail = useCustomInstance<SendEmailResponse>();

	return useCallback(
		(createEmail: CreateEmail, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postSendEmail(
				{
					url: `/api/sendEmail`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createEmail,
				},
				options,
			),
		[postSendEmail],
	);
};

export const usePostSendEmailMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostSendEmailHook>>>,
		TError,
		{ data: CreateEmail },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostSendEmailHook>>>,
	TError,
	{ data: CreateEmail },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postSendEmail = usePostSendEmailHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostSendEmailHook>>>,
		{ data: CreateEmail }
	> = (props) => {
		const { data } = props ?? {};

		return postSendEmail(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostSendEmailMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostSendEmailHook>>>>;
export type PostSendEmailMutationBody = CreateEmail;
export type PostSendEmailMutationError = ErrorPayload;

export const usePostSendEmail = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostSendEmailHook>>>,
		TError,
		{ data: CreateEmail },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostSendEmailHook>>>,
	TError,
	{ data: CreateEmail },
	TContext
> => {
	const mutationOptions = usePostSendEmailMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostCreateCheckoutSessionHook = () => {
	const postCreateCheckoutSession = useCustomInstance<StripeCheckoutResponse>();

	return useCallback(
		(
			stripeCheckoutRequest: StripeCheckoutRequest,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			postCreateCheckoutSession(
				{
					url: `/api/stripe/createCheckoutSession`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: stripeCheckoutRequest,
				},
				options,
			),
		[postCreateCheckoutSession],
	);
};

export const usePostCreateCheckoutSessionMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCreateCheckoutSessionHook>>>,
		TError,
		{ data: StripeCheckoutRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostCreateCheckoutSessionHook>>>,
	TError,
	{ data: StripeCheckoutRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postCreateCheckoutSession = usePostCreateCheckoutSessionHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostCreateCheckoutSessionHook>>>,
		{ data: StripeCheckoutRequest }
	> = (props) => {
		const { data } = props ?? {};

		return postCreateCheckoutSession(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostCreateCheckoutSessionMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostCreateCheckoutSessionHook>>>
>;
export type PostCreateCheckoutSessionMutationBody = StripeCheckoutRequest;
export type PostCreateCheckoutSessionMutationError = ErrorPayload;

export const usePostCreateCheckoutSession = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCreateCheckoutSessionHook>>>,
		TError,
		{ data: StripeCheckoutRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostCreateCheckoutSessionHook>>>,
	TError,
	{ data: StripeCheckoutRequest },
	TContext
> => {
	const mutationOptions = usePostCreateCheckoutSessionMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostCreateCustomerPortalSessionHook = () => {
	const postCreateCustomerPortalSession = useCustomInstance<StripePortalResponse>();

	return useCallback(
		(stripePortalRequest: StripePortalRequest, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postCreateCustomerPortalSession(
				{
					url: `/api/stripe/createCustomerPortalSession`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: stripePortalRequest,
				},
				options,
			),
		[postCreateCustomerPortalSession],
	);
};

export const usePostCreateCustomerPortalSessionMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCreateCustomerPortalSessionHook>>>,
		TError,
		{ data: StripePortalRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostCreateCustomerPortalSessionHook>>>,
	TError,
	{ data: StripePortalRequest },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postCreateCustomerPortalSession = usePostCreateCustomerPortalSessionHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostCreateCustomerPortalSessionHook>>>,
		{ data: StripePortalRequest }
	> = (props) => {
		const { data } = props ?? {};

		return postCreateCustomerPortalSession(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostCreateCustomerPortalSessionMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostCreateCustomerPortalSessionHook>>>
>;
export type PostCreateCustomerPortalSessionMutationBody = StripePortalRequest;
export type PostCreateCustomerPortalSessionMutationError = ErrorPayload;

export const usePostCreateCustomerPortalSession = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostCreateCustomerPortalSessionHook>>>,
		TError,
		{ data: StripePortalRequest },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostCreateCustomerPortalSessionHook>>>,
	TError,
	{ data: StripePortalRequest },
	TContext
> => {
	const mutationOptions = usePostCreateCustomerPortalSessionMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useUpdateTaskByIdHook = () => {
	const updateTaskById = useCustomInstance<Task>();

	return useCallback(
		(id: string, updateTask: UpdateTask, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateTaskById(
				{
					url: `/api/tasks/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateTask,
				},
				options,
			),
		[updateTaskById],
	);
};

export const useUpdateTaskByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateTaskByIdHook>>>,
		TError,
		{ id: string; data: UpdateTask },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateTaskByIdHook>>>,
	TError,
	{ id: string; data: UpdateTask },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateTaskById = useUpdateTaskByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateTaskByIdHook>>>,
		{ id: string; data: UpdateTask }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateTaskById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateTaskByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateTaskByIdHook>>>>;
export type UpdateTaskByIdMutationBody = UpdateTask;
export type UpdateTaskByIdMutationError = ErrorPayload;

export const useUpdateTaskById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateTaskByIdHook>>>,
		TError,
		{ id: string; data: UpdateTask },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateTaskByIdHook>>>,
	TError,
	{ id: string; data: UpdateTask },
	TContext
> => {
	const mutationOptions = useUpdateTaskByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteTaskByIdHook = () => {
	const deleteTaskById = useCustomInstance<Task>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteTaskById({ url: `/api/tasks/${id}`, method: 'DELETE' }, options),
		[deleteTaskById],
	);
};

export const useDeleteTaskByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteTaskByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteTaskByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteTaskById = useDeleteTaskByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteTaskByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteTaskById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteTaskByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteTaskByIdHook>>>>;

export type DeleteTaskByIdMutationError = ErrorPayload;

export const useDeleteTaskById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteTaskByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteTaskByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteTaskByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetTaskByIdHook = () => {
	const getTaskById = useCustomInstance<Task>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getTaskById({ url: `/api/tasks/${id}`, method: 'GET', signal }, options),
		[getTaskById],
	);
};

export const getGetTaskByIdQueryKey = (id: string) => [`/api/tasks/${id}`] as const;

export const useGetTaskByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTaskByIdQueryKey(id);

	const getTaskById = useGetTaskByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>> = ({ signal }) =>
		getTaskById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTaskByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>>;
export type GetTaskByIdQueryError = ErrorPayload;

export function useGetTaskById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTaskById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTaskById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTaskByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostTasksBatchHook = () => {
	const postTasksBatch = useCustomInstance<Task[]>();

	return useCallback(
		(updateTasksBatch: UpdateTasksBatch, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postTasksBatch(
				{
					url: `/api/tasks/batch`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: updateTasksBatch,
				},
				options,
			),
		[postTasksBatch],
	);
};

export const usePostTasksBatchMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTasksBatchHook>>>,
		TError,
		{ data: UpdateTasksBatch },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostTasksBatchHook>>>,
	TError,
	{ data: UpdateTasksBatch },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postTasksBatch = usePostTasksBatchHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostTasksBatchHook>>>,
		{ data: UpdateTasksBatch }
	> = (props) => {
		const { data } = props ?? {};

		return postTasksBatch(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostTasksBatchMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostTasksBatchHook>>>>;
export type PostTasksBatchMutationBody = UpdateTasksBatch;
export type PostTasksBatchMutationError = ErrorPayload;

export const usePostTasksBatch = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTasksBatchHook>>>,
		TError,
		{ data: UpdateTasksBatch },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostTasksBatchHook>>>,
	TError,
	{ data: UpdateTasksBatch },
	TContext
> => {
	const mutationOptions = usePostTasksBatchMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostTaskHook = () => {
	const postTask = useCustomInstance<Task>();

	return useCallback(
		(createTask: CreateTask, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postTask(
				{
					url: `/api/tasks`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createTask,
				},
				options,
			),
		[postTask],
	);
};

export const usePostTaskMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTaskHook>>>,
		TError,
		{ data: CreateTask },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostTaskHook>>>,
	TError,
	{ data: CreateTask },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postTask = usePostTaskHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostTaskHook>>>,
		{ data: CreateTask }
	> = (props) => {
		const { data } = props ?? {};

		return postTask(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostTaskHook>>>>;
export type PostTaskMutationBody = CreateTask;
export type PostTaskMutationError = ErrorPayload;

export const usePostTask = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTaskHook>>>,
		TError,
		{ data: CreateTask },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostTaskHook>>>,
	TError,
	{ data: CreateTask },
	TContext
> => {
	const mutationOptions = usePostTaskMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetTasksHook = () => {
	const getTasks = useCustomInstance<TaskWithRelations[]>();

	return useCallback(
		(
			params?: GetTasksParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getTasks({ url: `/api/tasks`, method: 'GET', params, signal }, options),
		[getTasks],
	);
};

export const getGetTasksQueryKey = (params?: GetTasksParams) => [`/api/tasks`, ...(params ? [params] : [])] as const;

export const useGetTasksQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>,
	TError = ErrorPayload,
>(
	params?: GetTasksParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey(params);

	const getTasks = useGetTasksHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>> = ({ signal }) =>
		getTasks(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>>;
export type GetTasksQueryError = ErrorPayload;

export function useGetTasks<TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ErrorPayload>(
	params: undefined | GetTasksParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTasks<TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ErrorPayload>(
	params?: GetTasksParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTasks<TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ErrorPayload>(
	params?: GetTasksParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTasks<TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ErrorPayload>(
	params?: GetTasksParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetTasksQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostTempNativeLogHook = () => {
	const postTempNativeLog = useCustomInstance<Payload>();

	return useCallback(
		(createTempNativeLog: CreateTempNativeLog, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postTempNativeLog(
				{
					url: `/api/tempNativeLog`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createTempNativeLog,
				},
				options,
			),
		[postTempNativeLog],
	);
};

export const usePostTempNativeLogMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTempNativeLogHook>>>,
		TError,
		{ data: CreateTempNativeLog },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostTempNativeLogHook>>>,
	TError,
	{ data: CreateTempNativeLog },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postTempNativeLog = usePostTempNativeLogHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostTempNativeLogHook>>>,
		{ data: CreateTempNativeLog }
	> = (props) => {
		const { data } = props ?? {};

		return postTempNativeLog(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostTempNativeLogMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostTempNativeLogHook>>>
>;
export type PostTempNativeLogMutationBody = CreateTempNativeLog;
export type PostTempNativeLogMutationError = ErrorPayload;

export const usePostTempNativeLog = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostTempNativeLogHook>>>,
		TError,
		{ data: CreateTempNativeLog },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostTempNativeLogHook>>>,
	TError,
	{ data: CreateTempNativeLog },
	TContext
> => {
	const mutationOptions = usePostTempNativeLogMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useUpdateUserRoleByIdHook = () => {
	const updateUserRoleById = useCustomInstance<UserRole>();

	return useCallback(
		(id: string, updateUserRole: UpdateUserRole, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateUserRoleById(
				{
					url: `/api/userRoles/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateUserRole,
				},
				options,
			),
		[updateUserRoleById],
	);
};

export const useUpdateUserRoleByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserRoleByIdHook>>>,
		TError,
		{ id: string; data: UpdateUserRole },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserRoleByIdHook>>>,
	TError,
	{ id: string; data: UpdateUserRole },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateUserRoleById = useUpdateUserRoleByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserRoleByIdHook>>>,
		{ id: string; data: UpdateUserRole }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateUserRoleById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateUserRoleByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserRoleByIdHook>>>
>;
export type UpdateUserRoleByIdMutationBody = UpdateUserRole;
export type UpdateUserRoleByIdMutationError = ErrorPayload;

export const useUpdateUserRoleById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserRoleByIdHook>>>,
		TError,
		{ id: string; data: UpdateUserRole },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserRoleByIdHook>>>,
	TError,
	{ id: string; data: UpdateUserRole },
	TContext
> => {
	const mutationOptions = useUpdateUserRoleByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteUserRoleByIdHook = () => {
	const deleteUserRoleById = useCustomInstance<UserRole>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteUserRoleById({ url: `/api/userRoles/${id}`, method: 'DELETE' }, options),
		[deleteUserRoleById],
	);
};

export const useDeleteUserRoleByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserRoleByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteUserRoleByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteUserRoleById = useDeleteUserRoleByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserRoleByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteUserRoleById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteUserRoleByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteUserRoleByIdHook>>>
>;

export type DeleteUserRoleByIdMutationError = ErrorPayload;

export const useDeleteUserRoleById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserRoleByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteUserRoleByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteUserRoleByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetUserRoleByIdHook = () => {
	const getUserRoleById = useCustomInstance<UserRole>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getUserRoleById({ url: `/api/userRoles/${id}`, method: 'GET', signal }, options),
		[getUserRoleById],
	);
};

export const getGetUserRoleByIdQueryKey = (id: string) => [`/api/userRoles/${id}`] as const;

export const useGetUserRoleByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetUserRoleByIdQueryKey(id);

	const getUserRoleById = useGetUserRoleByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>> = ({ signal }) =>
		getUserRoleById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetUserRoleByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>>;
export type GetUserRoleByIdQueryError = ErrorPayload;

export function useGetUserRoleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserRoleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserRoleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserRoleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetUserRoleByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useInviteUserHook = () => {
	const inviteUser = useCustomInstance<User>();

	return useCallback(
		(createUserRole: CreateUserRole, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			inviteUser(
				{
					url: `/api/userRoles/invite`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createUserRole,
				},
				options,
			),
		[inviteUser],
	);
};

export const useInviteUserMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useInviteUserHook>>>,
		TError,
		{ data: CreateUserRole },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useInviteUserHook>>>,
	TError,
	{ data: CreateUserRole },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const inviteUser = useInviteUserHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useInviteUserHook>>>,
		{ data: CreateUserRole }
	> = (props) => {
		const { data } = props ?? {};

		return inviteUser(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type InviteUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInviteUserHook>>>>;
export type InviteUserMutationBody = CreateUserRole;
export type InviteUserMutationError = ErrorPayload;

export const useInviteUser = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useInviteUserHook>>>,
		TError,
		{ data: CreateUserRole },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useInviteUserHook>>>,
	TError,
	{ data: CreateUserRole },
	TContext
> => {
	const mutationOptions = useInviteUserMutationOptions(options);

	return useMutation(mutationOptions);
};

export const usePostUserRoleHook = () => {
	const postUserRole = useCustomInstance<UserRole>();

	return useCallback(
		(createUserRole: CreateUserRole, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postUserRole(
				{
					url: `/api/userRoles`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createUserRole,
				},
				options,
			),
		[postUserRole],
	);
};

export const usePostUserRoleMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostUserRoleHook>>>,
		TError,
		{ data: CreateUserRole },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostUserRoleHook>>>,
	TError,
	{ data: CreateUserRole },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postUserRole = usePostUserRoleHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostUserRoleHook>>>,
		{ data: CreateUserRole }
	> = (props) => {
		const { data } = props ?? {};

		return postUserRole(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostUserRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostUserRoleHook>>>>;
export type PostUserRoleMutationBody = CreateUserRole;
export type PostUserRoleMutationError = ErrorPayload;

export const usePostUserRole = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostUserRoleHook>>>,
		TError,
		{ data: CreateUserRole },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostUserRoleHook>>>,
	TError,
	{ data: CreateUserRole },
	TContext
> => {
	const mutationOptions = usePostUserRoleMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetUserRolesByOwnerIdHook = () => {
	const getUserRolesByOwnerId = useCustomInstance<UserRoleWithRelations[]>();

	return useCallback(
		(
			params?: GetUserRolesByOwnerIdParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getUserRolesByOwnerId({ url: `/api/userRoles`, method: 'GET', params, signal }, options),
		[getUserRolesByOwnerId],
	);
};

export const getGetUserRolesByOwnerIdQueryKey = (params?: GetUserRolesByOwnerIdParams) =>
	[`/api/userRoles`, ...(params ? [params] : [])] as const;

export const useGetUserRolesByOwnerIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRolesByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetUserRolesByOwnerIdQueryKey(params);

	const getUserRolesByOwnerId = useGetUserRolesByOwnerIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>> = ({ signal }) =>
		getUserRolesByOwnerId(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetUserRolesByOwnerIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>
>;
export type GetUserRolesByOwnerIdQueryError = ErrorPayload;

export function useGetUserRolesByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetUserRolesByOwnerIdParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserRolesByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRolesByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserRolesByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRolesByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserRolesByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRolesByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRolesByOwnerIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetUserRolesByOwnerIdQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useGetUserRoleSettingsByOwnerIdHook = () => {
	const getUserRoleSettingsByOwnerId = useCustomInstance<UserRole[]>();

	return useCallback(
		(
			params?: GetUserRoleSettingsByOwnerIdParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getUserRoleSettingsByOwnerId({ url: `/api/userRoles/settings`, method: 'GET', params, signal }, options),
		[getUserRoleSettingsByOwnerId],
	);
};

export const getGetUserRoleSettingsByOwnerIdQueryKey = (params?: GetUserRoleSettingsByOwnerIdParams) =>
	[`/api/userRoles/settings`, ...(params ? [params] : [])] as const;

export const useGetUserRoleSettingsByOwnerIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRoleSettingsByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetUserRoleSettingsByOwnerIdQueryKey(params);

	const getUserRoleSettingsByOwnerId = useGetUserRoleSettingsByOwnerIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>> = ({
		signal,
	}) => getUserRoleSettingsByOwnerId(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetUserRoleSettingsByOwnerIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>
>;
export type GetUserRoleSettingsByOwnerIdQueryError = ErrorPayload;

export function useGetUserRoleSettingsByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetUserRoleSettingsByOwnerIdParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserRoleSettingsByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRoleSettingsByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserRoleSettingsByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRoleSettingsByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserRoleSettingsByOwnerId<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUserRoleSettingsByOwnerIdParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRoleSettingsByOwnerIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetUserRoleSettingsByOwnerIdQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateUserByIdHook = () => {
	const updateUserById = useCustomInstance<User>();

	return useCallback(
		(id: string, updateUser: UpdateUser, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			updateUserById(
				{
					url: `/api/users/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateUser,
				},
				options,
			),
		[updateUserById],
	);
};

export const useUpdateUserByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserByIdHook>>>,
		TError,
		{ id: string; data: UpdateUser },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserByIdHook>>>,
	TError,
	{ id: string; data: UpdateUser },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateUserById = useUpdateUserByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserByIdHook>>>,
		{ id: string; data: UpdateUser }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateUserById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateUserByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateUserByIdHook>>>>;
export type UpdateUserByIdMutationBody = UpdateUser;
export type UpdateUserByIdMutationError = ErrorPayload;

export const useUpdateUserById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserByIdHook>>>,
		TError,
		{ id: string; data: UpdateUser },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserByIdHook>>>,
	TError,
	{ id: string; data: UpdateUser },
	TContext
> => {
	const mutationOptions = useUpdateUserByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeletedUserByIdHook = () => {
	const deletedUserById = useCustomInstance<User>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deletedUserById({ url: `/api/users/${id}`, method: 'DELETE' }, options),
		[deletedUserById],
	);
};

export const useDeletedUserByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeletedUserByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeletedUserByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deletedUserById = useDeletedUserByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeletedUserByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deletedUserById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeletedUserByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletedUserByIdHook>>>>;

export type DeletedUserByIdMutationError = ErrorPayload;

export const useDeletedUserById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeletedUserByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeletedUserByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeletedUserByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetUserByIdHook = () => {
	const getUserById = useCustomInstance<User>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getUserById({ url: `/api/users/${id}`, method: 'GET', signal }, options),
		[getUserById],
	);
};

export const getGetUserByIdQueryKey = (id: string) => [`/api/users/${id}`] as const;

export const useGetUserByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(id);

	const getUserById = useGetUserByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>> = ({ signal }) =>
		getUserById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetUserByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>>;
export type GetUserByIdQueryError = ErrorPayload;

export function useGetUserById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserByIdHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetUserByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostUserHook = () => {
	const postUser = useCustomInstance<User>();

	return useCallback(
		(createUser: CreateUser, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postUser(
				{
					url: `/api/users`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createUser,
				},
				options,
			),
		[postUser],
	);
};

export const usePostUserMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostUserHook>>>,
		TError,
		{ data: CreateUser },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostUserHook>>>,
	TError,
	{ data: CreateUser },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postUser = usePostUserHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostUserHook>>>,
		{ data: CreateUser }
	> = (props) => {
		const { data } = props ?? {};

		return postUser(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostUserHook>>>>;
export type PostUserMutationBody = CreateUser;
export type PostUserMutationError = ErrorPayload;

export const usePostUser = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostUserHook>>>,
		TError,
		{ data: CreateUser },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostUserHook>>>,
	TError,
	{ data: CreateUser },
	TContext
> => {
	const mutationOptions = usePostUserMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetUsersHook = () => {
	const getUsers = useCustomInstance<User[]>();

	return useCallback(
		(
			params?: GetUsersParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getUsers({ url: `/api/users`, method: 'GET', params, signal }, options),
		[getUsers],
	);
};

export const getGetUsersQueryKey = (params?: GetUsersParams) => [`/api/users`, ...(params ? [params] : [])] as const;

export const useGetUsersQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>,
	TError = ErrorPayload,
>(
	params?: GetUsersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

	const getUsers = useGetUsersHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>> = ({ signal }) =>
		getUsers(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>>;
export type GetUsersQueryError = ErrorPayload;

export function useGetUsers<TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = ErrorPayload>(
	params: undefined | GetUsersParams,
	options: {
		query: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>> &
			Pick<
				DefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUsers<TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = ErrorPayload>(
	params?: GetUsersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>> &
			Pick<
				UndefinedInitialDataOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUsers<TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = ErrorPayload>(
	params?: GetUsersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUsers<TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = ErrorPayload>(
	params?: GetUsersParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetUsersQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useDeletedActualLoggedUserHook = () => {
	const deletedActualLoggedUser = useCustomInstance<Payload>();

	return useCallback(
		(options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deletedActualLoggedUser({ url: `/api/users`, method: 'DELETE' }, options),
		[deletedActualLoggedUser],
	);
};

export const useDeletedActualLoggedUserMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeletedActualLoggedUserHook>>>,
		TError,
		void,
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeletedActualLoggedUserHook>>>,
	TError,
	void,
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deletedActualLoggedUser = useDeletedActualLoggedUserHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeletedActualLoggedUserHook>>>,
		void
	> = () => deletedActualLoggedUser(requestOptions);

	return { mutationFn, ...mutationOptions };
};

export type DeletedActualLoggedUserMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeletedActualLoggedUserHook>>>
>;

export type DeletedActualLoggedUserMutationError = ErrorPayload;

export const useDeletedActualLoggedUser = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeletedActualLoggedUserHook>>>,
		TError,
		void,
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeletedActualLoggedUserHook>>>,
	TError,
	void,
	TContext
> => {
	const mutationOptions = useDeletedActualLoggedUserMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetWhisperAddressHook = () => {
	const getWhisperAddress = useCustomInstance<WhisperAddress[]>();

	return useCallback(
		(
			params: GetWhisperAddressParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getWhisperAddress({ url: `/api/whisperAddress`, method: 'GET', params, signal }, options),
		[getWhisperAddress],
	);
};

export const getGetWhisperAddressQueryKey = (params: GetWhisperAddressParams) =>
	[`/api/whisperAddress`, ...(params ? [params] : [])] as const;

export const useGetWhisperAddressQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
	TError = ErrorPayload,
>(
	params: GetWhisperAddressParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetWhisperAddressQueryKey(params);

	const getWhisperAddress = useGetWhisperAddressHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>> = ({ signal }) =>
		getWhisperAddress(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetWhisperAddressQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>
>;
export type GetWhisperAddressQueryError = ErrorPayload;

export function useGetWhisperAddress<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
	TError = ErrorPayload,
>(
	params: GetWhisperAddressParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWhisperAddress<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
	TError = ErrorPayload,
>(
	params: GetWhisperAddressParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWhisperAddress<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
	TError = ErrorPayload,
>(
	params: GetWhisperAddressParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetWhisperAddress<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>,
	TError = ErrorPayload,
>(
	params: GetWhisperAddressParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhisperAddressHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetWhisperAddressQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const useUpdateWorkScheduleByIdHook = () => {
	const updateWorkScheduleById = useCustomInstance<WorkSchedule>();

	return useCallback(
		(
			id: string,
			updateWorkSchedule: UpdateWorkSchedule,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
		) =>
			updateWorkScheduleById(
				{
					url: `/api/workSchedules/${id}`,
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					data: updateWorkSchedule,
				},
				options,
			),
		[updateWorkScheduleById],
	);
};

export const useUpdateWorkScheduleByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateWorkScheduleByIdHook>>>,
		TError,
		{ id: string; data: UpdateWorkSchedule },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useUpdateWorkScheduleByIdHook>>>,
	TError,
	{ id: string; data: UpdateWorkSchedule },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const updateWorkScheduleById = useUpdateWorkScheduleByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateWorkScheduleByIdHook>>>,
		{ id: string; data: UpdateWorkSchedule }
	> = (props) => {
		const { id, data } = props ?? {};

		return updateWorkScheduleById(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UpdateWorkScheduleByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateWorkScheduleByIdHook>>>
>;
export type UpdateWorkScheduleByIdMutationBody = UpdateWorkSchedule;
export type UpdateWorkScheduleByIdMutationError = ErrorPayload;

export const useUpdateWorkScheduleById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateWorkScheduleByIdHook>>>,
		TError,
		{ id: string; data: UpdateWorkSchedule },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useUpdateWorkScheduleByIdHook>>>,
	TError,
	{ id: string; data: UpdateWorkSchedule },
	TContext
> => {
	const mutationOptions = useUpdateWorkScheduleByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useDeleteWorkScheduleByIdHook = () => {
	const deleteWorkScheduleById = useCustomInstance<WorkSchedule>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			deleteWorkScheduleById({ url: `/api/workSchedules/${id}`, method: 'DELETE' }, options),
		[deleteWorkScheduleById],
	);
};

export const useDeleteWorkScheduleByIdMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteWorkScheduleByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof useDeleteWorkScheduleByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const deleteWorkScheduleById = useDeleteWorkScheduleByIdHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteWorkScheduleByIdHook>>>,
		{ id: string }
	> = (props) => {
		const { id } = props ?? {};

		return deleteWorkScheduleById(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteWorkScheduleByIdMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteWorkScheduleByIdHook>>>
>;

export type DeleteWorkScheduleByIdMutationError = ErrorPayload;

export const useDeleteWorkScheduleById = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteWorkScheduleByIdHook>>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof useDeleteWorkScheduleByIdHook>>>,
	TError,
	{ id: string },
	TContext
> => {
	const mutationOptions = useDeleteWorkScheduleByIdMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetWorkScheduleByIdHook = () => {
	const getWorkScheduleById = useCustomInstance<WorkSchedule>();

	return useCallback(
		(id: string, options?: SecondParameter<ReturnType<typeof useCustomInstance>>, signal?: AbortSignal) =>
			getWorkScheduleById({ url: `/api/workSchedules/${id}`, method: 'GET', signal }, options),
		[getWorkScheduleById],
	);
};

export const getGetWorkScheduleByIdQueryKey = (id: string) => [`/api/workSchedules/${id}`] as const;

export const useGetWorkScheduleByIdQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetWorkScheduleByIdQueryKey(id);

	const getWorkScheduleById = useGetWorkScheduleByIdHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>> = ({ signal }) =>
		getWorkScheduleById(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetWorkScheduleByIdQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>
>;
export type GetWorkScheduleByIdQueryError = ErrorPayload;

export function useGetWorkScheduleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkScheduleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkScheduleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetWorkScheduleById<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>,
	TError = ErrorPayload,
>(
	id: string,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkScheduleByIdHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetWorkScheduleByIdQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}

export const usePostWorkScheduleHook = () => {
	const postWorkSchedule = useCustomInstance<WorkSchedule>();

	return useCallback(
		(createWorkSchedule: CreateWorkSchedule, options?: SecondParameter<ReturnType<typeof useCustomInstance>>) =>
			postWorkSchedule(
				{
					url: `/api/workSchedules`,
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					data: createWorkSchedule,
				},
				options,
			),
		[postWorkSchedule],
	);
};

export const usePostWorkScheduleMutationOptions = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleHook>>>,
		TError,
		{ data: CreateWorkSchedule },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationOptions<
	Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleHook>>>,
	TError,
	{ data: CreateWorkSchedule },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const postWorkSchedule = usePostWorkScheduleHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleHook>>>,
		{ data: CreateWorkSchedule }
	> = (props) => {
		const { data } = props ?? {};

		return postWorkSchedule(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostWorkScheduleMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleHook>>>
>;
export type PostWorkScheduleMutationBody = CreateWorkSchedule;
export type PostWorkScheduleMutationError = ErrorPayload;

export const usePostWorkSchedule = <TError = ErrorPayload, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleHook>>>,
		TError,
		{ data: CreateWorkSchedule },
		TContext
	>;
	request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
}): UseMutationResult<
	Awaited<ReturnType<ReturnType<typeof usePostWorkScheduleHook>>>,
	TError,
	{ data: CreateWorkSchedule },
	TContext
> => {
	const mutationOptions = usePostWorkScheduleMutationOptions(options);

	return useMutation(mutationOptions);
};

export const useGetWorkSchedulesHook = () => {
	const getWorkSchedules = useCustomInstance<WorkSchedule[]>();

	return useCallback(
		(
			params?: GetWorkSchedulesParams,
			options?: SecondParameter<ReturnType<typeof useCustomInstance>>,
			signal?: AbortSignal,
		) => getWorkSchedules({ url: `/api/workSchedules`, method: 'GET', params, signal }, options),
		[getWorkSchedules],
	);
};

export const getGetWorkSchedulesQueryKey = (params?: GetWorkSchedulesParams) =>
	[`/api/workSchedules`, ...(params ? [params] : [])] as const;

export const useGetWorkSchedulesQueryOptions = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetWorkSchedulesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetWorkSchedulesQueryKey(params);

	const getWorkSchedules = useGetWorkSchedulesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>> = ({ signal }) =>
		getWorkSchedules(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type GetWorkSchedulesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>>;
export type GetWorkSchedulesQueryError = ErrorPayload;

export function useGetWorkSchedules<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
	TError = ErrorPayload,
>(
	params: undefined | GetWorkSchedulesParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkSchedules<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetWorkSchedulesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
					TError,
					TData
				>,
				'initialData'
			>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetWorkSchedules<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetWorkSchedulesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetWorkSchedules<
	TData = Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>,
	TError = ErrorPayload,
>(
	params?: GetWorkSchedulesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWorkSchedulesHook>>>, TError, TData>
		>;
		request?: SecondParameter<ReturnType<typeof useCustomInstance>>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
	const queryOptions = useGetWorkSchedulesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}
