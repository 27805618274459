import { forwardRef } from 'react';
import { Progress as ChakraProgress, IconButton } from '@chakra-ui/react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { ToggleTip } from './toggle-tip';

// eslint-disable-next-line react/display-name
export const ProgressBar = forwardRef<HTMLDivElement, ChakraProgress.TrackProps>((props, ref) => (
	<ChakraProgress.Track {...props} ref={ref}>
		<ChakraProgress.Range />
	</ChakraProgress.Track>
));

export const ProgressRoot = ChakraProgress.Root;
export const ProgressValueText = ChakraProgress.ValueText;

export interface ProgressLabelProps extends ChakraProgress.LabelProps {
	info?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const ProgressLabel = forwardRef<HTMLDivElement, ProgressLabelProps>((props, ref) => {
	const { children, info, ...rest } = props;
	return (
		<ChakraProgress.Label {...rest} ref={ref}>
			{children}
			{info && (
				<ToggleTip content={info}>
					<IconButton variant="ghost" aria-label="info" size="2xs" ms="1">
						<HiOutlineInformationCircle />
					</IconButton>
				</ToggleTip>
			)}
		</ChakraProgress.Label>
	);
});
