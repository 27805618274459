import { format } from 'date-fns';

export interface NextFetchRequestInit extends RequestInit {
	next?: {
		revalidate?: number | false;
		tags?: string[];
	};
	insecure?: boolean;
}

export type FetchInstanceParams = {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
	headers?: HeadersInit;
	signal?: AbortSignal;
	data?: unknown;
	params?: Record<string, string | object | number | string[] | null | boolean>;
};

function isPlainObject(value: unknown) {
	if (typeof value !== 'object' || value === null) {
		return false;
	}
	const prototype = Object.getPrototypeOf(value);
	return (
		(prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null) &&
		!(Symbol.toStringTag in value) &&
		!(Symbol.iterator in value)
	);
}

const stringifyDate = (date: Date | string): string => {
	// covert date to iso string and this format

	if (date instanceof Date) {
		return format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
	}
	return date;
};

function traverseAndChangeDates(obj: Record<string, any>): Record<string, any> {
	const newObj = { ...obj };
	Object.keys(newObj).forEach((key) => {
		if (newObj[key] instanceof Date) {
			newObj[key] = stringifyDate(newObj[key]);
		} else if (isPlainObject(newObj[key])) {
			newObj[key] = traverseAndChangeDates(newObj[key]);
		}
	});
	return newObj;
}

export const getUrl = ({ url, params }: FetchInstanceParams) => {
	// default url is deployemnt url
	const defaultApiUrl = process.env.VERCEL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: `http://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
	// you can override it with public api url (useful for production alias)
	const baseUrl = `${process.env.NEXT_PUBLIC_API_URL ?? process.env.EXPO_PUBLIC_API_URL ?? defaultApiUrl}${url}`;
	if (params) {
		const serializedParams: Record<string, string> = {};
		Object.entries(params).forEach(([key, value]) => {
			if (isPlainObject(value)) {
				serializedParams[key] = JSON.stringify(traverseAndChangeDates(value as Record<string, any>));
			} else {
				// at zije typescript a jeho type inference
				serializedParams[key] = JSON.stringify(stringifyDate(value as Date | string));
			}
		});
		return `${baseUrl}?${new URLSearchParams(serializedParams).toString()}`;
	}

	return baseUrl;
};

export const getFetchOptions = (
	{ url, method, data, signal, headers }: FetchInstanceParams,
	requestOptions?: NextFetchRequestInit,
) => {
	const copyHeader = { ...headers };
	// orval tam prida ten typ s multipart, ale fetch to vyplnuje automaticky i s boundary
	// coz je nutnost aby to fungovalo, proto se to musi ruco odmazat
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (copyHeader['Content-Type'] === 'multipart/form-data') {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		delete copyHeader['Content-Type'];
	}
	return {
		method,
		...(data ? { body: data instanceof FormData ? data : JSON.stringify(traverseAndChangeDates(data)) } : {}),
		signal,
		headers: copyHeader,
		...requestOptions,
		// todo nevim jak tomu podstrcit ten nextjs typ
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		next: {
			tags: [url, ...(requestOptions?.next?.tags ?? [])],
		},
	};
};
