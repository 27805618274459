'use client';

/* eslint-disable @next/next/no-img-element */

import React, { useState } from 'react';

import { Box, Circle, Flex, Grid, GridItem, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BiDollar, BiTask } from 'react-icons/bi';
import { FiBarChart2, FiChevronRight, FiClock } from 'react-icons/fi';

function Card({
	title,
	desc,
	icon,
	index,
	isClicked,
	onClick,
}: {
	title: string;
	desc: string;
	icon: React.ReactNode;
	index: number;
	isClicked: boolean;
	onClick: (index: number) => void;
}) {
	const handleClick = () => {
		onClick(index);
	};

	return (
		<Flex
			minW={{ base: '20.25rem', md: '28.5rem', xl: '35.5' }}
			gap={5}
			p={5}
			bg={isClicked ? 'primaryDark.100' : 'white'}
			transform="all 0.3s"
			onClick={handleClick}
			cursor="pointer"
			rounded="xl"
			direction={{ base: 'column', md: 'row' }}
			shadow="md"
			alignItems="center"
			textAlign={{ base: 'center', md: 'left' }}
			transition="all 0.3s ease"
			_hover={{
				bg: 'primaryDark.100',
				'& .icon': {
					bg: 'primaryLightAlpha.20',
					color: 'white',
				},
				'& > div > p': { color: 'neutralLight' },
				'& > div > h3': { color: 'neutralLight' },
				'& > div > div > svg': { color: 'primaryDark.100' },
				'& > span > svg': { color: 'white' },
			}}
			{...(isClicked && {
				bg: 'primaryDark.100',
				'& .icon': {
					bg: 'primaryLightAlpha.20',
					color: 'white',
				},
				'& > div > p': { color: 'neutralLight' },
				'& > div > h3': { color: 'neutralLight' },
				'& > div > div > svg': { color: 'white' },
			})}
		>
			<Circle
				size={16}
				bg="primaryDarkAlpha.10"
				className="icon"
				color={isClicked ? 'white' : 'primaryDark.100'}
				_hover={{
					color: 'white',
					bg: 'primaryDarkAlpha.10',
				}}
				transition="all 0.3s ease"
			>
				{icon}
			</Circle>
			<Stack gap={1}>
				<Heading
					as="h3"
					fontFamily="gabarito"
					lineHeight="120%"
					fontWeight={600}
					fontSize="xl"
					color="neutralDark"
					transition="color 0.3s ease"
				>
					{title}
				</Heading>
				<Text fontSize="md" color="gray.600" transition="color 0.3s ease">
					{desc}
				</Text>
			</Stack>
			<Box
				as="span"
				transition="color 0.3s ease"
				display={{ base: 'none', md: 'block' }}
				color={isClicked ? 'white' : 'gray.400'}
			>
				<FiChevronRight size={32} />
			</Box>
		</Flex>
	);
}

export default function Function() {
	const [selectedCard, setSelectedCard] = useState<number>(0);

	const handleCardClick = (index: number) => {
		if (selectedCard !== index) {
			setSelectedCard(index);
		}
	};

	return (
		<Flex
			id="product"
			as="section"
			py={{ base: 16, sm: 24 }}
			bg="#F4FCF9"
			bgImage="url('/images/prices-bg.jpg')"
			bgRepeat="no-repeat"
			bgSize="cover"
			overflow="hidden"
		>
			<Box maxW="1920px" w="full" pos="relative" marginInline="auto">
				<Box maxW="1400px" py={{ base: '6vw', lg: '0' }} marginInline="auto">
					<Grid
						templateColumns={{ base: '1fr', '2xl': '1fr 1fr' }}
						gapY={{ base: 0, '2xl': 4.5 }}
						alignItems="center"
						justifyContent="center"
					>
						<GridItem
							maxW={{ base: '100%', lg: '63%', xl: '50%', '2xl': '35.5rem' }}
							marginInline={{ base: 'auto', '2xl': '0' }}
							overflowX="auto"
						>
							<Heading
								fontWeight={500}
								color="neutralDark"
								fontSize={{ base: 32, sm: 48 }}
								lineHeight="120%"
								mb={12}
								textAlign="center"
								css={{
									textWrap: 'balance',
								}}
								as="h2"
							>
								Zjednodušte si den díky <br />
								<Text as="span" color="primaryDark.100">
									chytrým funkcím!
								</Text>
							</Heading>

							<Flex
								gap={{ base: 4, lg: 6 }}
								scrollbar="hidden"
								overflow="scroll"
								flexDir={{ base: 'row', lg: 'column' }}
								w="100%"
								px={{ base: 4, '2xl': 0 }}
								pb={{ base: 4.5, '2xl': 0 }}
							>
								<Card
									title="Docházka v&nbsp;reálném čase"
									desc="Živě sledujte příchody, odchody a&nbsp;výkazy zaměstnanců pro&nbsp;lepší přehled."
									icon={<FiClock size={32} />}
									index={0}
									isClicked={selectedCard === 0}
									onClick={handleCardClick}
								/>
								<Card
									title="Správa zakázek"
									desc="Efektivně spravujte a&nbsp;monitorujte průběh zakázek od&nbsp;začátku do&nbsp;konce."
									icon={<BiTask size={32} />}
									index={1}
									isClicked={selectedCard === 1}
									onClick={handleCardClick}
								/>
								<Card
									title="Analýza nákladů"
									desc="Pomocí výpočtů získáte přesné odhady nákladů na&nbsp;jednotlivé zakázky."
									icon={<BiDollar size={32} />}
									index={2}
									isClicked={selectedCard === 2}
									onClick={handleCardClick}
								/>
								<Card
									title="Přehledy a&nbsp;reporty"
									desc="Generujte detailní reporty o&nbsp;aktivitách vašich zaměstnanců a&nbsp;zakázkách."
									icon={<FiBarChart2 size={32} />}
									index={3}
									isClicked={selectedCard === 3}
									onClick={handleCardClick}
								/>
							</Flex>
						</GridItem>

						<GridItem
							marginInline={{ base: 'auto', '2xl': '0' }}
							position={{ base: 'static', '2xl': 'absolute' }}
							right="0px"
							maxW="60.75rem"
							w={{ base: '100%', '2xl': '50%' }}
							h="100%"
							px={{ base: '6vw', lg: '0' }}
						>
							<motion.div
								key={selectedCard}
								initial={{ opacity: 0, x: 50 }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: -50 }}
								transition={{ duration: 0.5 }}
								style={{ height: '100%' }}
							>
								<Image
									src={`./images/function-image-${selectedCard}.png`}
									alt=""
									style={{ width: '100%', height: '100%', objectFit: 'contain' }}
								/>
							</motion.div>
						</GridItem>
					</Grid>
				</Box>
			</Box>
		</Flex>
	);
}
