'use client';

import { Box, Flex, ProgressLabel, ProgressRoot, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import SolidBtn from '../landing/buttons/SolidBtn';
import { ProgressBar } from '../ui/progress';

// SubsTrialBox - need badding to the bottom of the sidebar ?
// 				  (when some parst are not loaded it has position under another element, not in the end of sidebar)
// SubsTrialBox - need implementation when starts trial

export function SubsTrialBox(props: { hasTrial: boolean | null | undefined; ownerId: string | undefined }) {
	const b = useTranslations('Subscription.Box');
	const { hasTrial, ownerId } = props;

	const daysLeft = 4;
	// hasTrial && freeTrialEndDate
	// 	? Math.max(0, Math.ceil((freeTrialEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))
	// 	: 0;

	return (
		hasTrial && (
			<Box backgroundColor="bg.neutralAccentAlpha" borderRadius="5px" p={4}>
				<Flex flexDir="column" justifyContent="center" alignItems="center">
					<Text mb={2} color="black" fontSize="18px" fontFamily="gabarito">
						{b('title')}
					</Text>

					<ProgressRoot
						colorPalette="green"
						defaultValue={daysLeft}
						max={14}
						maxW="sm"
						width="100%"
						aria-label="Progress"
					>
						<Flex flexDir="column" justifyContent="center" alignItems="center">
							<ProgressLabel>
								<Text mb={2} fontSize="15px" fontFamily="gabarito">
									{b('daysLeft')} {daysLeft.toString()}
								</Text>
							</ProgressLabel>
							<ProgressBar height="10px" width="100%" />
						</Flex>
					</ProgressRoot>

					<Box gap={2} mt={4}>
						<SolidBtn
							href={`/app/owners/${ownerId}/settings`}
							rounded="lg"
							width="100%"
							content={
								<Text textAlign="center" fontSize="14px">
									{b('btnLabel')}
								</Text>
							}
						/>
					</Box>
				</Flex>
			</Box>
		)
	);
}
